import Backend from "i18next-xhr-backend";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import conf from "env";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: ["de", "en", "it", "fr", "nl"],
    fallbackLng: "en",
    debug: conf.stage === "development",
    ns: ["emonvia"],
    defaultNS: "emonvia",
    react: {
      wait: true,
      useSuspense: false
    },
    backend: {
      loadPath: `/lang/{{lng}}.json`
    }
  });

export default i18n;
