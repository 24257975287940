import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import HelpCarousel from "pods/help/components/HelpCarousel";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";

class StepPlugIn extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <>
        <ScrollToTopOnMount />
        <HelpCarousel
          step={1}
          rightLink="/help/identify"
          icon="icon-white icon-plug-in"
          title={t("help.plugIn.title")}
        />
        <div className="row padding-20">
          <div className="help-bullet">{t("help.plugIn.bullet1")}</div>
        </div>
        <div className="row padding-20 no-padding-top">
          <div className="help-bullet">{t("help.plugIn.bullet2")}</div>
        </div>
      </>
    );
  }
}

export default withTranslation()(StepPlugIn);
