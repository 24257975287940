import * as React from "react";
import { Route, Switch } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";

import StepCharge from "pods/help/topic/StepCharge";
import StepIdentify from "pods/help/topic/StepIdentify";
import StepPayment from "pods/help/topic/StepPayment";
import StepPlugIn from "pods/help/topic/StepPlugIn";
import StepUnplug from "pods/help/topic/StepUnplug";

const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .help-bullet {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    min-height: 40px;
    font-size: 16px;
    line-height: 21px;
    position: relative;
    &:before {
      font-size: 20px;
      background-size: cover;
      content: "\0272A";
      position: absolute;
      left: 5px;
      text-align: center;
      width: 25px;
      top: 5px;
    }
    &.warning {
      &:before {
        width: 25px;
        height: 25px;
      }
    }
  }
  .help-status-list {
    text-transform: uppercase;
    margin-top: 20px;
    > .help-status-list-item {
      height: 35px;
      display: flex;
      align-items: center;
      > .color-pip {
        width: 36px;
        height: 15px;
        margin-right: 10px;
        border-radius: 7px;
      }
    }
  }
`;

class HelpIndexRoute extends React.Component<WithTranslation> {
  public render() {
    return (
      <HelpContainer>
        <Switch>
          <Route path="/help/plug-in" component={StepPlugIn} />
          <Route path="/help/identify" component={StepIdentify} />
          <Route path="/help/payment" component={StepPayment} />
          <Route path="/help/charge" component={StepCharge} />
          <Route path="/help/unplug" component={StepUnplug} />
        </Switch>
      </HelpContainer>
    );
  }
}

export default withTranslation()(HelpIndexRoute);
