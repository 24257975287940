import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import CarNotPluggedInStatusComponent from "./CarNotPluggedInStatusComponent";
import { connectVm } from "../../domain/connectVm";
import { ChargePointDetailsVm } from "pods/charge-point-details/ChargePointDetailsVm";

// tslint:disable-next-line: variable-name
export const CarNotPluggedInStatusContainer = connectVm(ChargePointDetailsVm)(
  withTranslation()(observer(CarNotPluggedInStatusComponent))
);
