import * as React from "react";
import styled from "styled-components";

import IdProvider from "utils/IdProvider";

interface IProps {
  value: boolean;
  onChange: (val: boolean) => void;
  label?: string | React.ReactNode;
  disabled?: boolean;
  id?: string;
}

const InputCheckbox: React.FC<IProps> = ({
  onChange = () => {},
  value = false,
  disabled = false,
  id = IdProvider.stringId,
  label
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(!!event.target.checked);
  };

  const setValue = (checked: boolean) => {
    onChange(checked);
  };

  return (
    <StyledCheckboxContainer>
      <input
        className="input-checkbox"
        id={id}
        checked={value}
        type="checkbox"
        disabled={disabled}
        onChange={onValueChange}
      />
      <label className="label-container" htmlFor={id}>
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#checkbox"></use>
          </svg>
        </span>
        {label && <span className="label">{label}</span>}
      </label>
      <svg className="inline-svg">
        <symbol id="checkbox" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </StyledCheckboxContainer>
  );
};

const StyledCheckboxContainer = styled.div`
  .label-container {
    user-select: none;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: flex;
  }
  .label-container:not(:last-child) {
    margin-right: 6px;
  }
  .label-container span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .label-container span:first-child {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
  }
  .label-container span:first-child svg {
    position: absolute;
    top: 6px;
    left: 5px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  .label-container span:last-child {
    padding-left: 8px;
    line-height: 18px;
  }
  .label-container:hover span:first-child {
    border-color: #ffca39;
  }
  .input-checkbox {
    position: absolute;
    visibility: hidden;
  }
  .input-checkbox:checked + .label-container span:first-child {
    background: #ffca39;
    border-color: #ffca39;
    animation: wave 0.4s ease;
  }
  .input-checkbox:checked + .label-container span:first-child svg {
    stroke-dashoffset: 0;
  }
  .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
  .label {
    font-family: Roobert-Medium, sans-serif;
    font-weight: 300;
    font-size: 13px;
    width: calc(100% - 24px);
    margin-top: 3px;
  }
  @media screen and (max-width: 640px) {
    .label-container {
      width: 100%;
    }
  }
  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
`;

export default InputCheckbox;
