import InputSelect from "components/input/InputSelect";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import { useEffect, useState } from "react";
import InputTextV2 from "components/input/InputTextV2";
import { getOperatorIdFromString, OperatorIds } from "enums/OperatorIds";
import axios from "axios";

interface IProps {
  onCodeSubmit: (evseId: string, operatorId?: string) => void;
}

interface IClientIpInfo {
  country: string;
  country_code: string;
}

const EnterCode: React.FC<IProps & WithTranslation> = ({ onCodeSubmit, t }) => {
  const [evseId, setEvseId] = useState("");
  const [operatorId, setOperatorId] = useState<string>(OperatorIds.DE);

  useEffect(() => {
    (async () => {
      const response = await axios.get<IClientIpInfo>("https://ipapi.co/json/");
      setOperatorId(getOperatorIdFromString(response.data.country_code) || OperatorIds.DE);
    })();
  }, []);

  const options = [
    { label: t("country.germany"), value: OperatorIds.DE },
    { label: t("country.italy"), value: OperatorIds.IT },
    { label: t("country.netherlands"), value: OperatorIds.NL },
    { label: t("country.switzerland"), value: OperatorIds.CH },
    { label: t("country.austria"), value: OperatorIds.AT },
    { label: t("country.france"), value: OperatorIds.FR },
    { label: t("country.belgium"), value: OperatorIds.BE },
    { label: t("country.luxembourg"), value: OperatorIds.LU }
  ].sort((a, b) => (a.label > b.label ? 1 : -1));

  const handleSubmitEvse = () => {
    if (!operatorId) {
      return;
    }
    onCodeSubmit(evseId, operatorId);
  };

  return (
    <div className="manual-identify">
      <Wrapper>
        <EnterCodeText>{t("identify.enterCode.content")}</EnterCodeText>
        <InputSelect
          selected={operatorId}
          onChange={setOperatorId}
          options={options}
          bordered
          title={t("identify.enterCode.country")}
        />
        <InputTextV2
          disabled={!operatorId}
          value={evseId}
          onChange={(value) => setEvseId(value.toUpperCase())}
          label={t("identify.enterCode.enterQrCode")}
          textBefore={operatorId}
        />
      </Wrapper>

      <div className="row margin-top-40">
        <button
          disabled={evseId.length !== 5}
          className="button primary"
          onClick={handleSubmitEvse}
          style={{ width: "100%" }}
        >
          {t("identify.enterCode.buttonContinue")}
        </button>
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 7px;
`;

const EnterCodeText = styled.div`
  text-align: center;
  line-height: 1.3;
  margin-bottom: 10px;
`;

export default withTranslation()(EnterCode);
