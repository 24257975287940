import { ServicePromise } from "domain/services/common/ServiceResult";

export interface IPaymentUserDetails {
  evseId: string;
  email: string;
}

export interface IUserPaymentRegistrationResponse {
  transactionClientSecret: string;
  consumerId: string;
  accountId: string;
  minCostInCents: number;
  maxCostInCents: number;
  currencyCode: string;
  countryCode: string;
}

export enum PaymentServiceResult {
  OK = "OK",
  NOT_FOUND = "NOT_FOUND",
  BAD_REQUEST = "BAD_REQUEST"
}

export interface IPaymentService {
  registerUser(
    paymentUserDetails: IPaymentUserDetails
  ): ServicePromise<PaymentServiceResult, IUserPaymentRegistrationResponse>;
}
