export enum OperatorIds {
  DE = "DE*ONE*",
  IT = "IT*ONE*",
  CH = "CH*UNO*",
  NL = "NL*ONE*",
  AT = "AT*ONE*",
  BE = "BE*ONE*",
  LU = "LU*ONE*",
  FR = "FR*ONE*"
}

export const getOperatorIdFromString = (countryCode: string) => {
  return OperatorIds[countryCode as keyof typeof OperatorIds];
};
