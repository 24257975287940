import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { StepContactInfoVm } from "./StepContactInfoVm";
import { connectVm } from "../../../domain/connectVm";
import StepContactInfoComponent from "./StepContactInfoComponent";

// tslint:disable-next-line: variable-name
export const StepContactInfoContainer = connectVm(StepContactInfoVm)(
  withTranslation()(observer(StepContactInfoComponent))
);
