import * as React from "react";
import { FC, useEffect, useState } from "react";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { ajaxAdapter } from "domain/AjaxAdapter";
import Routes, { replaceLocation } from "enums/Routes";
import UserModel from "pods/payment/models/UserModel";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { isNullOrUndefined } from "utils/isNullOrUndefined";
import { toEuro } from "utils/toEuro";

interface IProps extends RouteComponentProps {
  transactionClientSecret: string;
  consumerId: string;
  evseId: string;
  minCostInCents: number;
  maxCostInCents: number;
  user: UserModel;
  currencyCode: string;
  countryCode: string;
}

const CheckoutForm: FC<IProps> = ({
  consumerId,
  countryCode,
  currencyCode,
  evseId,
  maxCostInCents,
  minCostInCents,
  transactionClientSecret,
  user,
  history
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [enablePayment, setEnablePayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentRequestButton, setPaymentRequestButton] = useState(false);
  const [paymentRequestInState, setPaymentRequestInState] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const paymentRequest = stripe.paymentRequest({
      country: countryCode,
      currency: currencyCode.toLowerCase(),
      total: {
        label: t("applePayLabel"),
        amount: maxCostInCents
      },
      requestPayerName: true
    });

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequestButton(true);
        setPaymentRequestInState(paymentRequest);
      }
    });

    paymentRequest.on("paymentmethod", async (event) => {
      setLoading(true);

      stripe
        .confirmCardPayment(transactionClientSecret, { payment_method: event.paymentMethod.id })
        .then(async (response) => {
          if (response.error) {
            event.complete("fail");
            setErrorMessage(response.error.message);
          } else {
            event.complete("success");
            await callSuccess();
          }
        })
        .catch(() => {
          event.complete("fail");
          history.push(Routes.PAY_FAILED);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [stripe]);

  const onCardChange = (event: StripeCardElementChangeEvent) => {
    setEnablePayment(event.complete && isNullOrUndefined(event.error));
  };

  const onSubmit = (evt: React.MouseEvent | React.FormEvent) => {
    evt.preventDefault();
    setErrorMessage("");
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        setLoading(true);
        stripe
          .confirmCardPayment(transactionClientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                email: user.email
              }
            }
          })
          .then(async (response) => {
            if (response.error) {
              setErrorMessage(response.error.message);
            } else {
              await callSuccess();
            }
          })
          .catch(() => {
            history.push(Routes.PAY_FAILED);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const callSuccess = async () => {
    ajaxAdapter
      .post<{ config: { url: string } }>(`/mobile-site/ad-hoc/seamless/success/${consumerId}`)
      .then(() => {
        history.push(
          replaceLocation(Routes.TRANSACTION_DETAILS, {
            evseid: evseId,
            transactionId: consumerId
          })
        );
      })
      .catch(() => {
        history.push(Routes.PAY_FAILED);
      });
  };

  return (
    <>
      <CardElement
        onChange={onCardChange}
        className="stripe-card"
        options={{ hidePostalCode: true }}
      />
      <div>
        <button
          className={`button margin-top-20 ${enablePayment ? "" : "disabled"}`}
          disabled={!enablePayment || loading}
          onClick={onSubmit}
        >
          {t("buttonProceed")}
        </button>
        {paymentRequestButton && paymentRequestInState && (
          <PaymentRequestButtonElement
            className={"stripe-payment-request"}
            options={{
              paymentRequest: paymentRequestInState,
              style: {
                paymentRequestButton: {
                  theme: "dark",
                  height: "50px"
                }
              }
            }}
          />
        )}
      </div>
      <p className="row font-roboto-strong font-13 padding-bottom-10 padding-top-10">
        {t("costInfo", { min: toEuro(minCostInCents), currency: currencyCode })}
      </p>
      {errorMessage && (
        <p
          style={{
            height: 50,
            minHeight: 50,
            marginTop: 20
          }}
          className="color-error"
        >
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default withRouter(CheckoutForm);
