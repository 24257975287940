import { IPaymentStore } from "domain/stores/PaymentStore.interface";
import { IRootStore } from "domain/RootStore.interface";
import { IAjaxAdapter } from "domain/AjaxAdapter.interface";
import { ChargePointDetailsStore } from "./stores/charge-point-details/ChargePointDetailsStore";
import { IChargePointDetailsService } from "./services/charge-point-details/ChargePointDetailsService.interface";
import { PaymentStore } from "./stores/PaymentStore";
import { IPaymentService } from "./services/payment/PaymentService.interface";
import { IStopTransactionService } from "./services/charge-point-stop/StopTransactionService.interface";
import { StopTransactionStore } from "./stores/charge-point-stop/StopTransactionStore";

export class RootStore implements IRootStore {
  // all properties are explicitly null to detect
  // whether they have been set during initialization

  public ajax: IAjaxAdapter = null!;

  public ui: IPaymentStore = null!;
  public chargePointDetailsStore: ChargePointDetailsStore = null!;
  public paymentStore: PaymentStore = null!;
  public stopTransactionStore: StopTransactionStore = null!;

  public chargePointDetailsService: IChargePointDetailsService = null!;
  public paymentService: IPaymentService = null!;
  public stopTransactionService: IStopTransactionService = null!;
}
