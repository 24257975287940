import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { TransactionDetailsVm } from "./TransactionDetailsVm";
import CompletedTransactionDetailsComponent from "./CompletedTransactionDetailsComponent";
import { connectVm } from "../../domain/connectVm";

// tslint:disable-next-line: variable-name
export const CompletedTransactionDetailsContainer = connectVm(TransactionDetailsVm)(
  withTranslation()(observer(CompletedTransactionDetailsComponent))
);
