import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const chargePoint = require("../../../../assets/img/charge-point-station.png");

class ChargePointEmptyState extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;
    return (
      <div className="charge-point-details">
        <div className="text-center image align-and-justify-center padding-top-30">
          <div className="icon icon-black icon-charge" />
        </div>
        <div className="row">
          <div className="text-center font-roboto-light padding-top-30">
            {t("chargePointDetails.notFoundStatusMessage")}
          </div>
          <div className="row padding-top-30 align-and-justify-center">
            <img src={chargePoint} width={41} height={41} />
          </div>
        </div>
        <div className="row padding-top-30">
          <Link className="button secondary" to={"/identify"}>
            {t("chargePointDetails.useOtherStation")}
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChargePointEmptyState);
