import UseCase from "enums/UseCase";

export enum ChargePointStatus {
  AVAILABLE = "AVAILABLE",
  OCCUPIED = "OCCUPIED",
  OFFLINE = "OFFLINE",
  FAULTED = "FAULTED",
  CHARGING = "CHARGING"
}

export interface ICpoInformation {
  customerName: string;
  iconUrl: string;
  isPaymentActive: boolean;
  termsAndConditionsUrl: string;
}

export interface IInvoicingAccount {
  useCase: UseCase;
  centsPerKwh: number;
}

export class ChargePointDetailsModel {
  constructor(
    public readonly evseId: string,
    public readonly status: ChargePointStatus,
    public readonly cpoInformation: ICpoInformation,
    public readonly invoicingAccounts: IInvoicingAccount[],
    public readonly signedMeterValues: boolean,
    public readonly country: string,
    public readonly isReadyForCharging: boolean
  ) {}
}
