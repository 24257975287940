import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import RenderIf from "components/RenderIf";

interface IState {
  isContentVisible: boolean;
}

interface IProps {
  title?: string | React.ClassType<any, any, any>;
  hasToggleOption?: boolean;
  alwaysOpened?: boolean;
}

class ChargePointSectionItem extends React.Component<IProps & WithTranslation, IState> {
  public static defaultProps: IProps = {
    hasToggleOption: true
  };

  constructor(props: IProps & WithTranslation) {
    super(props);
    this.state = {
      isContentVisible: true
    };
  }

  public render() {
    const { title, children, hasToggleOption, alwaysOpened } = this.props;
    let { isContentVisible } = this.state;
    if (alwaysOpened) {
      isContentVisible = false;
    }
    return (
      <div className="charge-point-details">
        <div className="actions">
          <div
            className="action"
            onClick={
              hasToggleOption
                ? () => this.setState({ isContentVisible: !isContentVisible })
                : () => {}
            }
          >
            <div className="description title">{title}</div>
            <RenderIf renderContent={!!hasToggleOption}>
              <div
                className={`${
                  isContentVisible ? "icon-chevron-right rotate-90" : "icon-chevron-right"
                } icon-black icon-position-right`}
              ></div>
            </RenderIf>
          </div>
          <RenderIf renderContent={isContentVisible}>{children}</RenderIf>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChargePointSectionItem);
