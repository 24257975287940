import { ServicePromise } from "domain/services/common/ServiceResult";
import {
  ChargePointStatus,
  ICpoInformation,
  IInvoicingAccount
} from "domain/models/ChargePointDetailsModel";
import CreditCardTypes from "enums/CreditCardTypes";
import { Currency } from "domain/models/TransactionDetailsModel";

export interface IChargePointDetails {
  evseId: string;
  centsPerKwh: number;
  status: ChargePointStatus;
  cpoInformation: ICpoInformation;
  invoicingAccounts: IInvoicingAccount[];
  signedMeterValues: boolean;
  country: string;
  isReadyForCharging: boolean;
}

export interface IBucket {
  timestamp: Date;
  value: number;
}

export interface IChart {
  buckets: IBucket[];
  from: Date;
  stepSize: number;
  until: Date;
}

export interface ITransactionPaymentDetails {
  creditCardDigits: string;
  creditCardType: CreditCardTypes;
}

export interface ITransactionDetails {
  id: string;
  startDate: Date;
  startValue: number;
  totalKWh: number;
  powerConsumption: number;
  totalPrice: number;
  chart: IChart;
  endDate: Date;
  payment: ITransactionPaymentDetails;
  signedMeterValues: boolean;
  currency: Currency;
}

export interface IFileDownload {
  data: string;
}

export enum ServiceGetDetailsResult {
  OK = "OK",
  NOT_FOUND = "NOT_FOUND"
}

export interface IChargePointDetailsService {
  getChargePointDetails(
    id: string,
    isNfc: boolean
  ): ServicePromise<ServiceGetDetailsResult, IChargePointDetails>;
  getTransactionDetails(id: string): ServicePromise<ServiceGetDetailsResult, ITransactionDetails>;
  downloadSignedMeterValues(
    transactionId: string
  ): ServicePromise<ServiceGetDetailsResult, IFileDownload>;
}
