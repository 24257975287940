import { action } from "mobx";
import { IRootStore } from "domain/RootStore.interface";
import { IStopTransactionStore } from "./StopTransactionStore.interface";

export class StopTransactionStore implements IStopTransactionStore {
  constructor(
    // @ts-ignore
    private readonly root: IRootStore
  ) {}

  @action public stopTransaction = async (transactionId: string) => {
    const transactionDetailsResult = await this.root.stopTransactionService.stopTransaction(
      transactionId
    );
    if (!transactionDetailsResult.ok) {
      return Promise.reject(transactionDetailsResult);
    }
    return Promise.resolve(transactionDetailsResult);
  };
}
