export function cleanEvseID(evseId = "") {
  evseId = evseId.toUpperCase().replace(/[\W]/gi, "");

  // has operator id before
  if (evseId.length > 5) {
    evseId = evseId.slice(5);
  }

  return evseId;
}
