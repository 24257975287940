import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

const chargePointImage = require("../../../../assets/img/charge-point.svg");

interface IProps {
  statusClassName: string;
  status: string;
  evseId: string;
}
class ChargePointInactiveState extends React.Component<IProps & WithTranslation> {
  public render() {
    const { t, statusClassName, status, evseId } = this.props;
    return (
      <div className="charge-point-details">
        <div className="header-container">
          <img width="40%" src={chargePointImage} />
          <div>
            <div className="status">
              <div className={`${statusClassName} align-and-justify-center`}>
                {t(`common.status.${status.toLowerCase()}`)}
              </div>
            </div>
            <div className="font-roboto-light padding-top-10">{evseId}</div>
          </div>
        </div>
        <div className="row">
          <div className="text-center font-roboto-light padding-top-30">
            {t("chargePointDetails.inactiveStateMessage")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChargePointInactiveState);
