import * as React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps {
  step: number;
  icon: string;
  title: string;
  leftLink?: string;
  rightLink?: string;
}

class HelpCarousel extends React.Component<IProps & WithTranslation> {
  public render() {
    const { step, icon, title, leftLink, rightLink } = this.props;

    return (
      <div className="help-carousel">
        <div className="bg">
          <div className="icon icon-check" />
        </div>
        <div className="content">
          <div className="number">{step}</div>
          <div className={`icon ${icon}`} />
          <div className="title">{title}</div>
        </div>
        <div className="navigation">
          {leftLink && (
            <NavLink to={leftLink} className="left">
              <div className="icon icon-white icon-chevron-right horizontally-mirror-image" />
            </NavLink>
          )}
          {rightLink && (
            <NavLink to={rightLink} className="right">
              <div className="icon icon-white icon-chevron-right" />
            </NavLink>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(HelpCarousel);
