import { RootStore } from "domain/RootStore";
import { IRootStore } from "domain/RootStore.interface";
import { AjaxAdapter } from "domain/AjaxAdapter";
import { PaymentStore } from "domain/stores/PaymentStore";
import { ChargePointDetailsStore } from "domain/stores/charge-point-details/ChargePointDetailsStore";
import { ChargePointDetailsService } from "./services/charge-point-details/ChargePointDetailsService";
import { PaymentService } from "./services/payment/PaymentService";
import { StopTransactionService } from "./services/charge-point-stop/StopTransactionService";
import { StopTransactionStore } from "./stores/charge-point-stop/StopTransactionStore";

export const createRootStore = (): IRootStore => {
  const root = new RootStore();

  root.ajax = new AjaxAdapter();
  root.paymentStore = new PaymentStore(root);
  root.chargePointDetailsStore = new ChargePointDetailsStore(root);
  root.chargePointDetailsService = new ChargePointDetailsService(root);
  root.paymentService = new PaymentService(root);
  root.stopTransactionService = new StopTransactionService();
  root.stopTransactionStore = new StopTransactionStore(root);
  return root;
};

export const defaultRootStore = createRootStore();
