import * as React from "react";
import { RouteComponentProps } from "react-router";
import { WithTranslation } from "react-i18next";

import InputText from "components/input/InputText";
import RenderIf from "components/RenderIf";
import env from "env";
import PaymentNavigation from "pods/payment/components/PaymentNavigation";
import { StepContactInfoVm } from "./StepContactInfoVm";
import StepCardInfo from "./StepCardInfo";
import i18n from "utils/i18n";
import Routes, { replaceLocation } from "enums/Routes";
import PaymentService from "domain/services/payment/PaymentService";
import { IUserPaymentRegistrationResponse } from "domain/services/payment/PaymentService.interface";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";
import { isEmail } from "utils/isEmail";
import InputCheckbox from "components/input/InputCheckbox";
import IdProvider from "utils/IdProvider";

const contactInfoBg = require("../../../../assets/img/icons/contact-info.png");

const ITALY_COUNTRY_CODE = "ITA";

interface IMatch {
  evseid: string;
}

interface IState {
  isProcessingPayment: boolean;
  payment?: IUserPaymentRegistrationResponse;
  isItalianCp: boolean;
}

interface IProps extends RouteComponentProps<IMatch>, WithTranslation {}

interface IProps {
  vm: StepContactInfoVm;
}

class StepContactInfoComponent extends React.Component<IProps, IState> {
  private termsCheckboxId = IdProvider.stringId;
  private operatorCheckboxId = IdProvider.stringId;

  public interval: number;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isProcessingPayment: false,
      payment: undefined,
      isItalianCp: false
    };
  }

  private registerUserForPayment = async () => {
    const { vm } = this.props;
    const { user } = vm;
    try {
      this.setState({ isProcessingPayment: true });
      const payment = await PaymentService.registerUser(user);
      this.setState({ isProcessingPayment: false, payment: payment.data });
    } catch (error) {
      this.setState({ isProcessingPayment: false });
    }
  };

  private get customerName() {
    const { vm } = this.props;
    if (isNotNullOrUndefined(vm.chargePointDetails)) {
      return vm.chargePointDetails.cpoInformation.customerName;
    }
  }

  private setTestData = async () => {
    this.props.vm.setTestData();
  };

  private onBackClick = () => {
    const { vm } = this.props;
    const { evseid } = this.props.match.params;

    if (vm.transactionClientSecret) {
      vm.setClientSecret(null);
      return;
    }

    this.props.history.push(`${Routes.QR}?evseid=${evseid}`);
  };

  public componentDidMount() {
    const { vm } = this.props;
    const { evseid } = this.props.match.params;

    vm.getChargePointDetails(evseid).then(() => {
      const isItalianCp = vm.chargePointDetails.country === ITALY_COUNTRY_CODE;

      this.setState({ isItalianCp });

      vm.user.update({
        evseId: evseid,
        locale: i18n.language,
        hasAcceptedOperatorTerms: !isItalianCp
      });
    });

    i18n.on("languageChanged", (lng) => {
      vm.user.update({ locale: lng });
    });

    this.interval = window.setInterval(() => {
      vm.getChargePointDetails(evseid);
    }, 5000);
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
  }

  public render() {
    const {
      t,
      vm,
      match: {
        params: { evseid }
      },
      history
    } = this.props;
    const { user } = vm;
    const { isProcessingPayment, payment, isItalianCp } = this.state;
    if (payment && payment.consumerId) {
      return (
        <StepCardInfo
          consumerId={payment.consumerId}
          onBackClick={this.onBackClick}
          transactionClientSecret={payment.transactionClientSecret}
          accountId={payment.accountId}
          evseId={evseid}
          minCostInCents={payment.minCostInCents}
          maxCostInCents={payment.maxCostInCents}
          user={user}
          currencyCode={payment.currencyCode}
          countryCode={payment.countryCode}
        />
      );
    }

    if (
      isNotNullOrUndefined(vm.chargePointDetails) &&
      !vm.chargePointDetails.isReadyForCharging
    ) {
      history.push(replaceLocation(Routes.HELPERS_CAR_NOT_PLUGGED, { evseid }));
    }

    return (
      <>
        <div className="row align-and-justify-center padding-top-20">
          <img src={contactInfoBg} alt={t("welcome.welcome")} width={62} height={62} />
        </div>
        <PaymentNavigation
          step={1}
          stepsTotal={2}
          title={t("pay.stepContactInfo.title")}
          onBackClick={this.onBackClick}
        />
        <div className="padding-20">
          <div className="row">
            <div className="row font-roboto-medium font-18 padding-top-20 padding-bottom-10 text-center">
              {t("pay.stepContactInfo.contactInfoLabel", { customerName: this.customerName })}
            </div>
          </div>
          <div className="row margin-top-10">
            <InputText
              name="email"
              autoComplete="email"
              title={t("pay.stepContactInfo.email")}
              value={user.email || ""}
              valid={user.email ? isEmail(user.email) : true}
              validationMessage={t("common.invalidEmail")}
              onChange={(email) => user.update({ email: email.replace(/\s/, "") })}
            />
          </div>

          <div className="row align-center terms-container margin-top-20">
            <div className="margin-right-10">
              <InputCheckbox
                id={this.termsCheckboxId}
                value={user.hasAcceptedTerms}
                onChange={(hasAcceptedTerms) => user.update({ hasAcceptedTerms })}
                label={
                  <div dangerouslySetInnerHTML={{ __html: t("pay.stepContactInfo.cpoAccept") }} />
                }
              />
            </div>
          </div>

          {isItalianCp && (
            <div className="row terms-container padding-top-20">
              <div className="margin-right-10">
                <InputCheckbox
                  id={this.operatorCheckboxId}
                  value={user.hasAcceptedOperatorTerms}
                  onChange={(hasAcceptedOperatorTerms) => user.update({ hasAcceptedOperatorTerms })}
                  label={t("pay.stepContactInfo.operatorTerms")}
                />
              </div>
            </div>
          )}

          <div className="terms-container margin-top-20 margin-left-8">
            <div
              className="font-roboto-light font-13"
              dangerouslySetInnerHTML={{ __html: t("pay.stepContactInfo.privacyPolicy") }}
            />
          </div>
        </div>
        {vm.errorCode && (
          <div className="row color-error text-center">{t(`errorCodes.${vm.errorCode}`)}</div>
        )}
        <div className="row">
          <button
            className="button"
            disabled={!vm.complete || isProcessingPayment}
            onClick={this.registerUserForPayment}
          >
            {t("pay.stepContactInfo.nextStep")}
          </button>
          <RenderIf renderContent={env.isDev}>
            <button className="button development-only" onClick={this.setTestData}>
              Fill with test data
            </button>
          </RenderIf>
        </div>
      </>
    );
  }
}

export default StepContactInfoComponent;
