import * as React from "react";

interface IProps {
  width?: number;
  height?: number;
  onClick?: () => void;
  fontColor?: string;
  underlineColor?: string;
  style?: React.CSSProperties;
}

const Logo: React.FC<IProps> = ({
  fontColor = "#000",
  height = 30,
  onClick,
  underlineColor = "#ffca39",
  width = 100,
  style
}) => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      x="0px"
      y="0px"
      viewBox="0 0 110 42"
      style={style}
      width={width}
      height={height}
      onClick={onClick}
    >
      <path
        style={{ fill: fontColor }}
        d={`M5.09178 3.16038V0.39502H0V28.4876H5.09178V13.6073H5.13567C5.09178
            13.3439 4.78452 9.13006 8.25219 6.58417C10.6225 4.82838 14.0902 5.13564 14.9242
            5.22343V0.482809C9.43735 -0.0439267 6.80367 2.15081 5.7063 3.42375C5.57462 3.55543
            5.35515 3.55544 5.22346 3.46765C5.09178 3.37986 5.09178 3.29207 5.09178 3.16038Z`}
        shapeRendering="geometricPrecision"
      />
      <path
        style={{ fill: fontColor }}
        d={`M46.3964 16.7239C46.5281 15.9777 46.572 15.1876 46.572 14.4413C46.572 6.45251
            39.9878 0 31.8673 0C23.7468 0 17.1626 6.49641 17.1626 14.4413C17.1626 22.3863
            23.7468 28.8827 31.8673 28.8827C37.1347 28.8827 41.3485 27.2586 44.9918 22.7813L41.6119
            20.1038C39.4611 23.0886 35.335 24.4493 31.8673 24.3176C27.1706 24.3176 23.3517 21.2011
            22.6933 17.1189C22.6494 16.8994 22.825 16.7239 23.0445 16.68C23.0884 16.68 23.0884 16.68
            23.0884 16.68M22.7372 12.3344C23.3956 7.94493 27.2584 4.52115 31.9112 4.52115C36.564
            4.52115 40.4268 7.90104 41.0852 12.3344C41.1291 12.5539 40.9535 12.7733 40.734 12.7733C40.6901
            12.7733 40.6901 12.7733 40.6901 12.7733H23.1323C22.9128 12.7733 22.7372 12.5978 22.7372
            12.3783C22.6933 12.3344 22.6933 12.3344 22.7372 12.3344Z`}
        shapeRendering="geometricPrecision"
      />
      <path
        style={{ fill: fontColor }}
        d={`M79.4931 16.7239C79.6248 15.9777 79.6687 15.1876 79.6687 14.4413C79.6687 6.45251 73.0845 0
            64.964 0C56.8435 0 50.2593 6.49641 50.2593 14.4413C50.2593 22.3863 56.8435 28.8827 64.964
            28.8827C70.2313 28.8827 74.4452 27.2586 78.0885 22.7813L74.7086 20.1038C72.5578 23.0886
            68.4317 24.4493 64.964 24.3176C60.2673 24.3176 56.4484 21.2011 55.79 17.1189C55.7461 16.8994
            55.9217 16.7239 56.1412 16.68C56.1851 16.68 56.1851 16.68 56.1851 16.68M55.8339 12.3344C56.4923
            7.94493 60.355 4.52115 65.0079 4.52115C69.6607 4.52115 73.5234 7.90104 74.1819 12.3344C74.2258
            12.5539 74.0502 12.7733 73.8307 12.7733C73.7868 12.7733 73.7868 12.7733 73.7868 12.7733H56.229C56.0095
            12.7733 55.8339 12.5978 55.8339 12.3783C55.8339 12.3344 55.8339 12.3344 55.8339 12.3344Z`}
        shapeRendering="geometricPrecision"
      />
      <path
        style={{ fill: fontColor }}
        d={`M104.03 1.09738L96.0414 20.8939C95.9536 21.1572 95.6903 21.245 95.4708 21.1572C95.3391 21.1133 95.2513
            21.0256 95.2074 20.8939L87.1308 1.09738C86.9991 0.702333 86.5602 0.438965 86.1651 0.438965H81.1611L93.4516
            28.5754H97.7533L110 0.438965H104.996C104.557 0.438965 104.162 0.658438 104.03 1.09738Z`}
        shapeRendering="geometricPrecision"
      />
      <path
        style={{ fill: underlineColor }}
        d={`M52.4541 37.9688H107.981C108.903 37.9688 109.649 38.715 109.649 39.6367C109.649 40.5585 108.903 41.3047
            107.981 41.3047H52.4541C51.5323 41.3047 50.7861 40.5585 50.7861 39.6367C50.7861 38.6711 51.5323 37.9688 52.4541
            37.9688Z`}
        shapeRendering="geometricPrecision"
      />
    </svg>
  );
};

export default Logo;
