import * as React from "react";
import Languages, { getNativeName } from "enums/Languages";
import i18n from "utils/i18n";
import styled from "styled-components";

const LanguagePicker: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [dropdownRef]);

  const changeLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLLIElement>, lang: Languages) => {
    if (e.key === "Enter") {
      changeLanguage(lang);
    }
  };

  const toggleShowPicker = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <StyledLanguagePicker isOpen={isOpen} onClick={toggleShowPicker} ref={dropdownRef}>
      <div className="lang-toggle">
        <span className="icon-globe" />
        <span className="lang-label">{i18n.language}</span>
        <span className="icon-arrow-down" />
      </div>
      {isOpen && (
        <ol className="dropdown">
          {languageOptions.map((lang) => {
            const isActive = lang.value === i18n.language;

            return (
              <li
                key={lang.value}
                className={isActive ? "active" : ""}
                onClick={() => changeLanguage(lang.value)}
                onKeyDown={(e) => onKeyDown(e, lang.value)}
                tabIndex={0}
              >
                {lang.value.toLocaleUpperCase()} - {lang.label}
                {isActive && <span className="checkmark">✓</span>}
              </li>
            );
          })}
        </ol>
      )}
    </StyledLanguagePicker>
  );
};

const languageOptions = [
  { value: Languages.DE, label: getNativeName(Languages.DE) },
  { value: Languages.EN, label: getNativeName(Languages.EN) },
  { value: Languages.IT, label: getNativeName(Languages.IT) },
  { value: Languages.FR, label: getNativeName(Languages.FR) },
  { value: Languages.NL, label: getNativeName(Languages.NL) }
];

const StyledLanguagePicker = styled.div<{ isOpen: boolean }>`
  float: right;
  position: relative;

  .lang-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background-color: white;
    transition: filter 100ms ease-in;
    border-radius: 10px;
    padding: 10px;

    :hover {
      filter: brightness(0.9);
    }
  }

  .lang-label {
    margin-top: 3px;
    padding-bottom: 3px;
    border-bottom: 2px solid #ffca39;
    text-transform: uppercase;
  }

  .icon-arrow-down {
    margin-bottom: 2px;
  }

  .dropdown {
    position: absolute;
    top: 45px;
    right: 0;
    user-select: none;
    width: max-content;
    background-color: white;
    padding: 5px 20px;
    border-radius: 10px;
    border: 1px solid #858b92;

    li {
      background-color: white;
      color: #494d53;
      margin: 0 -20px;
      padding: 15px 20px;
      cursor: pointer;

      .checkmark {
        float: right;
        margin-left: 20px;
      }
    }

    .active {
      color: #131923;
      font-weight: 500;
    }

    li:hover {
      filter: brightness(0.95);
    }

    li:not(:last-of-type) {
      border-bottom: 1px solid #e2e4e5;
    }
  }
`;

export default LanguagePicker;
