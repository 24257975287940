import * as React from "react";
import { ChangeEvent } from "react";

import IdProvider from "utils/IdProvider";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";

interface IProps {
  id?: string;
  value: string;
  onChange?: (val: string) => void;
  placeholder?: string;
  title?: string;
  disabled?: boolean;
  type?: string;
  valid?: boolean;
  validationMessage?: string;
  autoComplete?: string;
  name?: string;
  autoCapitalize?: string;
}

interface IState {
  value: string;
}

export default class InputText extends React.PureComponent<IProps, IState> {
  private readonly componentId = this.props.id || IdProvider.stringId;

  public static defaultProps: IProps = {
    value: "",
    disabled: false,
    type: "text"
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  public static getDerivedStateFromProps(nextProps: IProps) {
    return {
      value: isNotNullOrUndefined(nextProps.value) ? nextProps.value.toString() : ""
    };
  }

  private onValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(evt.target.value);
    }
  };

  public render() {
    const additionalProperties: { required?: boolean; pattern?: string } = {};
    const fieldIsInvalid = isNotNullOrUndefined(this.props.valid) && !this.props.valid;
    const showValidationMessage = fieldIsInvalid && this.props.validationMessage;

    if (!!this.props.title) {
      additionalProperties.required = true;
      additionalProperties.pattern = ".+";
    }

    return (
      <div
        className={`input-group ${!this.props.title ? "no-title" : ""} ${
          fieldIsInvalid ? "invalid" : ""
        }`}
      >
        <input
          {...additionalProperties}
          className="input"
          value={this.state.value}
          id={this.componentId}
          disabled={this.props.disabled}
          autoCapitalize={this.props.autoCapitalize}
          type={this.props.type}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          onChange={this.onValueChange}
          name={this.props.name}
        />
        <label className="label" htmlFor={this.componentId}>
          {this.props.title}
        </label>
        {showValidationMessage && (
          <span className="validation-message">{this.props.validationMessage}</span>
        )}
      </div>
    );
  }
}
