import * as React from "react";
import { NavLink } from "react-router-dom";
import { Trans, withTranslation, WithTranslation } from "react-i18next";

import HelpCarousel from "pods/help/components/HelpCarousel";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";

class StepIdentify extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <>
        <ScrollToTopOnMount />
        <HelpCarousel
          step={2}
          leftLink="/help/plug-in"
          rightLink="/help/payment"
          icon="icon-white icon-identify"
          title={t("help.identify.title")}
        />
        <div className="row padding-20">
          <div className="help-bullet">
            <Trans i18nKey="help.identify.bullet1" />
          </div>
        </div>
        <div className="row padding-top-10 padding-bottom-10">
          <NavLink to="/identify" className="button primary">
            {t("welcome.identify")}
          </NavLink>
        </div>
        <div className="row padding-20">
          <div className="help-bullet">
            <Trans i18nKey="help.identify.bullet2" />
          </div>
        </div>
        <div className="row padding-20 no-padding-top">
          <div className="help-bullet">
            <Trans i18nKey="help.identify.bullet3" />
            <ul className="help-status-list">
              <li className="help-status-list-item">
                <span className="color-pip bg-color-available" />
                {t("common.status.available")}
              </li>
              <li className="help-status-list-item">
                <span className="color-pip bg-color-occupied" />
                {t("common.status.occupied")}
              </li>
              <li className="help-status-list-item">
                <span className="color-pip bg-color-charging" />
                {t("common.status.charging")}
              </li>
              <li className="help-status-list-item">
                <span className="color-pip bg-color-faulted" />
                {t("common.status.faulted")}
              </li>
              <li className="help-status-list-item">
                <span className="color-pip bg-color-offline" />
                {t("common.status.offline")}
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(StepIdentify);
