import { computed } from "mobx";
import * as moment from "moment";

import { IRootStore } from "domain/RootStore.interface";
import UseCase from "enums/UseCase";

export class TransactionDetailsVm {
  constructor(private readonly root: IRootStore) {}

  private _refreshing: boolean = false;

  public get refreshing() {
    return this._refreshing;
  }

  public getTransactionDetails = async (transactionId: string) => {
    this._refreshing = true;
    return this.root.chargePointDetailsStore
      .getTransactionDetails(transactionId)
      .finally(() => (this._refreshing = false));
  };

  @computed public get transactionDetails() {
    return this.root.chargePointDetailsStore.transactionDetails;
  }

  public stopTransaction = async (transactionId: string) => {
    this._refreshing = true;
    await this.root.stopTransactionStore.stopTransaction(transactionId);
    this._refreshing = false;
  };

  public getChargePointDetails = async (id: string) => {
    return this.root.chargePointDetailsStore.getChargePointDetails(id, false);
  };

  @computed public get chargePointDetails() {
    return this.root.chargePointDetailsStore.chargePointDetails;
  }

  @computed public get chargePointPublicPricing() {
    return this.root.chargePointDetailsStore.chargePointDetails.invoicingAccounts.find(
      (ia) => ia.useCase === UseCase.FREE_CHARGING || ia.useCase === UseCase.AD_HOC
    );
  }

  @computed public get transactionDuration() {
    const transactionStartDate = moment(this.transactionDetails.startDate);
    const transactionEndDate = moment(this.transactionDetails.endDate);
    const hours = transactionEndDate.diff(transactionStartDate, "hours");
    const minutes = transactionEndDate.diff(transactionStartDate, "minutes");
    return `${hours}:${minutes}`;
  }
}
