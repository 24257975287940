import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import LanguagePicker from "components/input/LanguagePicker";
import Logo from "components/Logo";

class Navigation extends React.Component<WithTranslation> {
  /* HTML structure and style directly taken from reev website */
  public render() {
    return (
      <div className="navigation-legacy">
        <header className="header">
          <div className="container-fluid">
            <div className="header__wrapper">
              <div className="padding-left-10">
                <a href="/">
                  <Logo height={50} style={{ marginTop: 10 }} />
                </a>
              </div>
              <div className="flex-1">
                <LanguagePicker />
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default withTranslation()(Navigation);
