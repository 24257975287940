import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import { ICpoInformation } from "domain/models/ChargePointDetailsModel";

const providerIcon = require("../../../../assets/img/provider-icon.png");

interface IProps extends WithTranslation {
  cpoInformation: ICpoInformation;
  price: number;
  country: string;
}

class ProviderAndPriceInfo extends React.Component<IProps> {
  public render() {
    const { cpoInformation, price, country, t } = this.props;

    return (
      <div>
        <div className="provider row align-center">
          <div>
            <img
              style={{ maxWidth: 100, maxHeight: 66 }}
              src={cpoInformation.iconUrl || providerIcon}
            />
          </div>
          <div className="padding-left-10 flex-1">
            <div className="actions-content margin-bottom-20">
              <div className="padding-top-10 font-roboto-strong font-15">
                {t("chargePointDetails.guests")}
              </div>
              <div className="padding-top-10 font-roboto-strong font-24">
                {price}
                <span className="font-roboto-light font-15 padding-left-5">
                  {country === "CHE"
                    ? t("chargePointDetails.rappenKwh")
                    : t("chargePointDetails.centKwh")}
                </span>
              </div>
              <div className="padding-top-10 font-roboto-light font-15">
                {t("chargePointDetails.mwSt")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProviderAndPriceInfo);
