import * as React from "react";
import { RouteComponentProps } from "react-router";
import { WithTranslation } from "react-i18next";
import * as queryString from "query-string";

import { ChargePointDetailsVm } from "./ChargePointDetailsVm";
import { ChargePointStatus } from "domain/models/ChargePointDetailsModel";
import ChargePointEmptyState from "./components/ChargePointEmptyState";
import ChargePointInactiveState from "./components/ChargePointInactiveState";

import AdHocInvoicingAccountDetails from "./components/AdHocInvoicingAccountDetails";
import RoamingInvoicingAccountDetails from "./components/RoamingInvoicingAccountDetails";
import ChargeAtWorkInvoicingAccountDetails from "./components/ChargeAtWorkInvoicingAccountDetails";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";
import Routes from "enums/Routes";
import { isAblQrCode, redirectAblQrCode } from "utils/AblQrCodes";

const chargePointImage = require("../../../assets/img/charge-point.svg");

interface IChargePointStatusContent {
  translationKey?: string;
  className: string;
}

interface IProps extends WithTranslation {
  vm: ChargePointDetailsVm;
}

class ChargePointDetailsIndexRouteComponent extends React.Component<IProps & RouteComponentProps> {
  public state = {
    chargePointStatusContent: new Map<string, IChargePointStatusContent>(),
    loading: true
  };

  private get queryParams() {
    const queryParams = queryString.parse(this.props.location.search);

    return Object.assign(
      {
        evseid: "",
        nfc: false
      },
      queryParams
    );
  }

  public hasAssignedInvoicingAccount = (invoicingAccount: any) => {
    const { vm } = this.props;

    if (
      !vm.chargePointDetails.invoicingAccounts ||
      vm.chargePointDetails.invoicingAccounts.length === 0
    ) {
      return false;
    }
    const found = vm.chargePointDetails.invoicingAccounts.find((element) => {
      return element.useCase === invoicingAccount;
    });
    return !!found;
  };

  public componentDidMount() {
    const { chargePointStatusContent: chargePointStatusContent } = this.state;
    const { vm, history } = this.props;
    const { evseid, nfc } = this.queryParams;

    if (isAblQrCode(evseid)) {
      redirectAblQrCode(evseid);
      return;
    }

    this.setState({ loading: true });

    vm.getChargePointDetails(evseid, nfc)
      .catch(() => history.push(Routes.IDENTIFY))
      .finally(() => this.setState({ loading: false }));
    chargePointStatusContent.set(ChargePointStatus.AVAILABLE, {
      translationKey: "chargePointDetails.statusAvailable",
      className: "available"
    });
    chargePointStatusContent.set(ChargePointStatus.OCCUPIED, {
      translationKey: "chargePointDetails.statusOccupied",
      className: "occupied"
    });
    chargePointStatusContent.set(ChargePointStatus.OFFLINE, {
      translationKey: "chargePointDetails.statusOffline",
      className: "offline"
    });
    chargePointStatusContent.set(ChargePointStatus.FAULTED, {
      translationKey: "chargePointDetails.statusFaulted",
      className: "faulted"
    });
    chargePointStatusContent.set(ChargePointStatus.CHARGING, {
      translationKey: "chargePointDetails.statusCharging",
      className: "charging"
    });
  }

  public render() {
    const { t, vm } = this.props;
    const { chargePointStatusContent: chargePointStatusContent, loading } = this.state;

    if (loading) {
      return <div style={{ display: "flex", flex: 1 }} />;
    }

    if (!vm.chargePointDetails || chargePointStatusContent.size === 0) {
      return <ChargePointEmptyState />;
    }
    const { evseid } = this.queryParams;
    const statusContent = chargePointStatusContent.get(
      vm.chargePointDetails!.status
    ) as IChargePointStatusContent;

    if (vm.chargePointDetails.invoicingAccounts.length === 0) {
      return (
        <ChargePointInactiveState
          status={vm.chargePointDetails.status}
          statusClassName={statusContent.className}
          evseId={vm.chargePointDetails.evseId}
        />
      );
    }

    if (vm.chargePointPublicPricing) {
      return (
        <div className="charge-point-details">
          <div className="header-container">
            <img
              width="40%"
              src={chargePointImage}
              alt={vm.chargePointDetails.cpoInformation.customerName}
            />
            <div>
              <div className="status">
                <div className={`${statusContent.className} align-and-justify-center`}>
                  {t(`common.status.${vm.chargePointDetails.status.toLowerCase()}`)}
                </div>
              </div>
              <div className="font-roboto-light padding-top-10">{vm.chargePointDetails.evseId}</div>
            </div>
          </div>
          <AdHocInvoicingAccountDetails
            evseid={evseid}
            chargePointDetails={vm.chargePointDetails}
            price={vm.chargePointPublicPricing!.centsPerKwh}
            isFreeChargingApplied={vm.isFreeChargingApplied}
          />
        </div>
      );
    }

    if (vm.chargePointRoamingPricing) {
      return (
        <div className="charge-point-details">
          <div className="header-container">
            <img
              width="40%"
              src={chargePointImage}
              alt={vm.chargePointDetails.cpoInformation.customerName}
            />
            <div>
              <div className="status">
                <div className={`${statusContent.className} align-and-justify-center`}>
                  {t(`common.status.${vm.chargePointDetails.status.toLowerCase()}`)}
                </div>
              </div>
              <div className="font-roboto-light padding-top-10">{vm.chargePointDetails.evseId}</div>
            </div>
          </div>
          <RoamingInvoicingAccountDetails />
        </div>
      );
    }

    if (isNotNullOrUndefined(vm.chargePointChargeAtWorkPricing)) {
      return (
        <div className="charge-point-details">
          <div className="header-container">
            <img
              width="40%"
              src={chargePointImage}
              alt={vm.chargePointDetails.cpoInformation.customerName}
            />
            <div>
              <div className="status">
                <div className={`${statusContent.className} align-and-justify-center`}>
                  {t(`common.status.${vm.chargePointDetails.status.toLowerCase()}`)}
                </div>
              </div>
              <div className="font-roboto-light padding-top-10">{vm.chargePointDetails.evseId}</div>
            </div>
          </div>
          <ChargeAtWorkInvoicingAccountDetails />
        </div>
      );
    }

    return <ChargePointEmptyState />;
  }
}

export default ChargePointDetailsIndexRouteComponent;
