import { computed } from "mobx";

import { IRootStore } from "domain/RootStore.interface";
import UseCase from "../../enums/UseCase";

export class ChargePointDetailsVm {
  constructor(private readonly root: IRootStore) {}

  private _refreshing: boolean = false;

  public get refreshing() {
    return this._refreshing;
  }

  public getChargePointDetails = async (id: string, isNfc: boolean) => {
    await this.root.chargePointDetailsStore.getChargePointDetails(id, isNfc);
  };

  @computed public get chargePointDetails() {
    return this.root.chargePointDetailsStore.chargePointDetails;
  }

  @computed public get chargePointPublicPricing() {
    return this.root.chargePointDetailsStore.chargePointDetails.invoicingAccounts.find(
      (ia) => ia.useCase === UseCase.FREE_CHARGING || ia.useCase === UseCase.AD_HOC
    );
  }

  @computed public get chargePointRoamingPricing() {
    return this.root.chargePointDetailsStore.chargePointDetails.invoicingAccounts.find(
      (ia) => ia.useCase === UseCase.ROAMING
    );
  }

  @computed public get chargePointChargeAtWorkPricing() {
    return this.root.chargePointDetailsStore.chargePointDetails.invoicingAccounts.find(
      (ia) => ia.useCase === UseCase.CHARGE_AT_WORK
    );
  }

  @computed public get isFreeChargingApplied() {
    return !!this.root.chargePointDetailsStore.chargePointDetails.invoicingAccounts.find(
      (ia) => ia.useCase === UseCase.FREE_CHARGING
    );
  }
}
