import * as React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";

class WelcomeRoute extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <div className="welcome">
        <div className="intro">
          <h1 className="title">{t("welcome.title")}</h1>
          <span className="sub-title">{t("welcome.subTitle")}</span>
        </div>
        <div className="row padding-top-30 padding-bottom-50">
          <NavLink to="/identify" className="button primary">
            {t("welcome.identify")}
          </NavLink>
        </div>
        <div className="row">
          <div className="text-center font-roboto-light font-18 padding-bottom-15">
            {t("welcome.actionsTitle")}
          </div>
        </div>
        <div className="actions">
          <NavLink to="/help/plug-in" className="action">
            <div className="icon icon-black icon-plug-in" />
            <div className="description">{t("welcome.actions.plugIn")}</div>
          </NavLink>
          <NavLink to="/help/identify" className="action">
            <div className="icon icon-black icon-identify" />
            <div className="description">{t("welcome.actions.identify")}</div>
          </NavLink>
          <NavLink to="/help/payment" className="action">
            <div className="icon icon-black icon-payment" />
            <div className="description">{t("welcome.actions.payment")}</div>
          </NavLink>
          <NavLink to="/help/charge" className="action">
            <div className="icon icon-black icon-charge" />
            <div className="description">{t("welcome.actions.charge")}</div>
          </NavLink>
          <NavLink to="/help/unplug" className="action">
            <div className="icon icon-black icon-unplug" />
            <div className="description">{t("welcome.actions.unplug")}</div>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default withTranslation()(WelcomeRoute);
