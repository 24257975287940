import {
  isAjaxAdapterError,
  IAjaxAdapterErrorResponse
} from "domain/AjaxAdapter.interface";
import { ServiceResult, ServicePromise } from "domain/services/common/ServiceResult";
import { AjaxAdapter } from "domain/AjaxAdapter";
import {
  IPaymentService,
  PaymentServiceResult,
  IUserPaymentRegistrationResponse,
  IPaymentUserDetails
} from "./PaymentService.interface";
import { AxiosResponse } from "axios";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";
import ErrorCodes from "enums/ErrorCodes";

export class PaymentService implements IPaymentService {
  constructor(
    // @ts-ignore
    private readonly root: IRootStore,
    private readonly ajaxAdapter: AjaxAdapter = new AjaxAdapter()
  ) {}

  public registerUser = async (
    paymentUserDetails: IPaymentUserDetails
  ): ServicePromise<PaymentServiceResult, IUserPaymentRegistrationResponse> => {
    const userPaymentRegisterResult = await this.ajaxAdapter.post<IUserPaymentRegistrationResponse>(
      "/mobile-site/ad-hoc/seamless/register", {
        evseId: paymentUserDetails.evseId,
        email: paymentUserDetails.email
      }
    );
    if (isAjaxAdapterError(userPaymentRegisterResult)) {
      const errorResponse = userPaymentRegisterResult.response as AxiosResponse<
        IAjaxAdapterErrorResponse
      >;
      const errorCode = isNotNullOrUndefined(errorResponse)
        ? errorResponse.data.error.errorCode
        : ErrorCodes.BACKEND_NOT_RESPONDING;
      return Promise.reject(errorCode);
    }

    switch (userPaymentRegisterResult.status) {
      case 200:
      case 201:
        const registerUserResults = userPaymentRegisterResult.data;
        return ServiceResult.ok(PaymentServiceResult.OK, registerUserResults);
      case 400:
        return ServiceResult.fail(PaymentServiceResult.BAD_REQUEST);
      case 404:
        return ServiceResult.fail(PaymentServiceResult.NOT_FOUND);
      default:
        return Promise.reject(
          `PaymentService.registerUser(): Unknown status code: ${userPaymentRegisterResult.status}`
        );
    }
  };
}

export default new PaymentService(new AjaxAdapter());
