import axios, { AxiosRequestConfig } from "axios";
import { observable, computed, runInAction } from "mobx";

import env from "env";
import { IAjaxAdapter, IInvokeOptions, AjaxAdapterResponse } from "domain/AjaxAdapter.interface";

export class AjaxAdapter implements IAjaxAdapter {
  @computed private get basePath() {
    return env.apiUrl;
  }

  @computed private get defaultHeaders() {
    return {
      "Content-Type": "application/json"
    };
  }

  @observable private _requestCount = 0;

  @computed private get requestCount(): number {
    return this._requestCount;
  }

  private set requestCount(value: number) {
    this._requestCount = Math.max(0, value);
  }

  @computed public get isBusy(): boolean {
    return this.requestCount > 0;
  }

  private async invoke<T>(
    method: string,
    url: string,
    data?: any,
    options: IInvokeOptions = { headers: {} }
  ): Promise<AjaxAdapterResponse<T>> {
    runInAction(() => this._requestCount++);

    let headers = options.headers || {};
    headers = { ...this.defaultHeaders, ...headers };

    try {
      return await axios({
        method,
        url: `${this.basePath}${url}`,
        headers,
        data
      } as AxiosRequestConfig);
    } catch (err) {
      // TODO - Throw or return?
      return err;
    } finally {
      runInAction(() => this._requestCount--);
    }
  }

  public get<T>(url: string, options?: IInvokeOptions): Promise<AjaxAdapterResponse<T>> {
    return this.invoke<T>("GET", url, null, options);
  }

  public post<T>(
    url: string,
    data: any = null,
    options?: IInvokeOptions
  ): Promise<AjaxAdapterResponse<T>> {
    return this.invoke<T>("POST", url, data, options);
  }

  public put<T>(
    url: string,
    data?: any,
    options?: IInvokeOptions
  ): Promise<AjaxAdapterResponse<T>> {
    return this.invoke<T>("PUT", url, data, options);
  }

  public delete<T>(url: string, options?: IInvokeOptions): Promise<AjaxAdapterResponse<T>> {
    return this.invoke("DELETE", url, null, options);
  }
}

export const ajaxAdapter = new AjaxAdapter();
