import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const welcomeBg = require("../../../../assets/img/welcome-bg.jpg");
const failedImage = require("../../../../assets/img/failed-img.svg");

class PaymentFailed extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;
    return (
      <>
        <div className="pay">
          <div className="image">
            <img src={welcomeBg} alt={t("welcome.welcome")} />
          </div>
          <div className="align-and-justify-center completed-image-container">
            <img src={failedImage} alt={t("welcome.welcome")} width={80} height={80} />
          </div>
          <div className="padding-top-20 align-and-justify-center font-roboto-medium font-28">
            {t("pay.stepFailed.title")}
          </div>
          <div className="padding-top-20 text-center font-roboto-light font-18">
            {t("pay.stepFailed.subtitle")}
          </div>
          <div className="padding-20 align-and-justify-center font-roboto-light font-13 text-center">
            {t("pay.stepFailed.description")}
          </div>
          <div className="padding-top-20 align-and-justify-center">
            <NavLink to={"/"} className="button primary">
              {t("common.buttonOk")}
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PaymentFailed);
