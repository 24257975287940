import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";
import RenderIf from "components/RenderIf";
import IdentifyOption from "enums/IdentifyOptions";
import { IRootStore } from "domain/RootStore.interface";
import { ChargePointDetailsService } from "domain/services/charge-point-details/ChargePointDetailsService";
import Routes from "enums/Routes";
import { isAblQrCode, redirectAblQrCode } from "utils/AblQrCodes";
import { cleanEvseID } from "utils/EvseId";
import EnterCode from "pods/identify-charge-point/components/EnterCode";
import QrCodeScan from "pods/identify-charge-point/components/QrCodeScan";

const qrPlaceholder = require("../../../assets/img/qr-code-placeholder-image.png");

interface IState {
  identifyOption: IdentifyOption;
  qrReaderActive: boolean;
}

class IdentifyChargePointOptionsIndexRoute extends React.Component<
  RouteComponentProps & WithTranslation,
  IState
> {
  private readonly root: IRootStore;
  constructor(props: any) {
      super(props);
      this.state = {
          identifyOption: IdentifyOption.QR,
          qrReaderActive: false,
      };
  }

  public setIdentifyOption(identifyOption: IdentifyOption) {
      this.setState({ identifyOption });
  }

  private navigateToProperUrl = (url: string) => {
      const interchargeKeyword = "intercharge";
      const { history } = this.props;
      const evseId = new URL(url).searchParams.get("evseid")!.slice(-5);
      if (url.toString().includes(interchargeKeyword)) {
          const cpdService = new ChargePointDetailsService(this.root);
          cpdService
              .getChargePointDetails(evseId!.toString(), false)
              .then((chargePointDetails) => {
                  if (chargePointDetails && chargePointDetails.ok) {
                      history.push(`${Routes.QR}?evseid=${evseId}`);
                  } else {
                      window.location.href = url;
                  }
              })
              .catch(() => {
                  history.push(Routes.IDENTIFY);
              });
      } else {
          this.onCodeSubmit(evseId);
      }
  };

  public handleScan = (data: string) => {
      if (data) {
          this.navigateToProperUrl(data);
      }
  };

  public onCodeSubmit = (evseid: string) => {
      const { history } = this.props;
      evseid = cleanEvseID(evseid);
      if (isAblQrCode(evseid)) {
          redirectAblQrCode(evseid);
          return;
      }
      if (evseid.length) {
          history.push(`${Routes.QR}?evseid=${evseid}`);
      }
  };

  public handleError = (err: any) => {
      console.error(err);
  };

  public render() {
      const { identifyOption, qrReaderActive } = this.state;
      const { t } = this.props;

      return (
          <div className="identify">
              <div className="row padding-bottom-10">
                  <div className="title text-center padding-top-30">{t("identify.qrCodetitle")}</div>
                  <div className="row align-and-justify-center margin-top-20 options align-center">
                      <div
                          className={`${identifyOption === IdentifyOption.QR ? "active" : ""} padding-10`}
                          onClick={() => this.setIdentifyOption(IdentifyOption.QR)}
                      >
                          {t("identify.options.scan")}
                      </div>
                      <div className="separator" />
                      <div
                          className={`${identifyOption === IdentifyOption.MANUAL ? "active" : ""} padding-10`}
                          onClick={() => this.setIdentifyOption(IdentifyOption.MANUAL)}
                      >
                          {t("identify.options.enter")}
                      </div>
                  </div>
              </div>
              <RenderIf renderContent={identifyOption === IdentifyOption.QR && !qrReaderActive}>
                  <div className="text-center padding-20">
                      <img className="image" src={qrPlaceholder} alt={t("identify.codeScan")} />
                      <div
                          onClick={() => this.setState({ qrReaderActive: true })}
                          className="button primary margin-top-40"
                      >
                          {t("identify.codeScan")}
                      </div>
                  </div>
              </RenderIf>
              <RenderIf renderContent={qrReaderActive && identifyOption === IdentifyOption.QR}>
                  <QrCodeScan handleScan={this.handleScan} handleError={this.handleError} />
              </RenderIf>
              <RenderIf renderContent={identifyOption === IdentifyOption.MANUAL}>
                  <EnterCode onCodeSubmit={this.onCodeSubmit} />
              </RenderIf>
          </div>
      );
  }
}

export default withTranslation()(IdentifyChargePointOptionsIndexRoute);
