import {
  IChart,
  ITransactionPaymentDetails
} from "domain/services/charge-point-details/ChargePointDetailsService.interface";

export enum Currency {
  EUR = "EUR",
  CHF = "CHF"
}

export function getCurrencySign(currency: Currency): string {
  switch (currency) {
    case Currency.EUR:
      return "€";
    case Currency.CHF:
      return "CHF";
  }
}

export class TransactionDetailsModel {
  constructor(
    public readonly id: string,
    public readonly chart: IChart,
    public readonly endDate: Date,
    public readonly payment: ITransactionPaymentDetails,
    public readonly currency: Currency,
    public readonly startDate: Date,
    public readonly startValue: number,
    public readonly totalKWh: number,
    public readonly powerConsumption: number,
    public readonly totalPrice: number,
    public readonly signedMeterValues?: boolean
  ) {}
}
