import { observable, action, computed } from "mobx";

import { IPaymentStore } from "domain/stores/PaymentStore.interface";
import UserModel from "pods/payment/models/UserModel";
import { isEmpty } from "utils/isEmpty";
import { IUserPaymentRegistrationResponse } from "domain/services/payment/PaymentService.interface";

export class PaymentStore implements IPaymentStore {
  constructor(
    // @ts-ignore
    private readonly root: IRootStore
  ) {}

  @observable public isInitialized: boolean = false;
  @observable public _transactionClientSecret: string = "";
  @observable public minCostInCents: number = 0;
  @observable public maxCostInCents: number = 0;
  @observable public currencyCode: string = "eur";
  @observable public consumerId: string = "";
  @observable public accountId: string = "";
  @observable public _user: UserModel = new UserModel();
  @observable public _errorCode: string;

  public registerUser = async (user: UserModel) => {
    const registerUserResults = await this.root.paymentService.registerUser(user);
    if (!registerUserResults.ok) {
      return Promise.reject(registerUserResults);
    }
    this.setRegisterInfo(registerUserResults.data);

    return Promise.resolve(this._transactionClientSecret);
  };

  @action public setRegisterInfo(registerUserResults: IUserPaymentRegistrationResponse) {
    this._transactionClientSecret = registerUserResults.transactionClientSecret;
    this.consumerId = registerUserResults.consumerId;
    this.accountId = registerUserResults.accountId;
    this.minCostInCents = registerUserResults.minCostInCents;
    this.maxCostInCents = registerUserResults.maxCostInCents;
  }

  @action public setTransactionClientSecret(transactionClientSecret: string) {
    this._transactionClientSecret = transactionClientSecret;
  }

  @computed public get transactionClientSecret() {
    return this._transactionClientSecret;
  }

  @action public setUser(user: UserModel) {
    this._user = user;
    return this._user;
  }

  @computed public get user() {
    return this._user;
  }

  @computed public get complete(): boolean {
    return (
      !isEmpty(this._user.email) &&
      this._user.hasAcceptedTerms &&
      this._user.hasAcceptedOperatorTerms
    );
  }

  @action public setErrorCode(errorCode: string) {
    this._errorCode = errorCode;
    return this._errorCode;
  }

  @computed public get errorCode() {
    return this._errorCode;
  }
}
