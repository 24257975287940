import * as React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";

import HelpCarousel from "pods/help/components/HelpCarousel";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";

class StepCharge extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <>
        <ScrollToTopOnMount />
        <HelpCarousel
          step={4}
          leftLink="/help/payment"
          rightLink="/help/unplug"
          icon="icon-white icon-charge"
          title={t("help.charge.title")}
        />
        <div className="row padding-20">
          <div className="help-bullet">
            <Trans i18nKey="help.charge.bullet1" />
          </div>
        </div>
        <div className="row padding-20 no-padding-top">
          <div className="help-bullet">
            <Trans i18nKey="help.charge.bullet2" />
          </div>
        </div>
        <div className="row padding-20 no-padding-top">
          <div className="help-bullet">
            <Trans i18nKey="help.charge.bullet3" />
          </div>
        </div>
        <div className="row padding-20 padding-top-10">
          <div className="help-bullet warning">
            <Trans i18nKey="help.charge.bullet4" />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(StepCharge);
