import * as React from "react";
import { WithTranslation } from "react-i18next";
import * as moment from "moment";

import { TransactionDetailsVm } from "./TransactionDetailsVm";
import { RouteComponentProps } from "react-router";
import ReactLoading from "react-loading";
import AdHocInvoicingAccountDetails from "pods/charge-point-details/components/AdHocInvoicingAccountDetails";
import { IRootStore } from "domain/RootStore.interface";
import { ChargePointDetailsService } from "domain/services/charge-point-details/ChargePointDetailsService";
import { downloadFile } from "utils/DownloadFile";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";
import Routes from "enums/Routes";
import { getCurrencySign } from "domain/models/TransactionDetailsModel";
const chargePointImage = require("../../../assets/img/charge-point.svg");

interface IProps extends WithTranslation {
  vm: TransactionDetailsVm;
}

interface IMatch {
  transactionId: string;
  evseid: string;
}

class CompletedTransactionDetailsComponent extends React.Component<
  IProps & RouteComponentProps<IMatch>
> {
  private readonly root: IRootStore;

  public componentDidMount() {
    const { vm, history } = this.props;
    Promise.all([
      vm.getTransactionDetails(this.transactionId),
      vm.getChargePointDetails(this.evesId)
    ]).catch(() => history.push(Routes.PAY_FAILED));
  }

  public get transactionDuration() {
    const { vm } = this.props;
    const transactionStartDate = moment(vm.transactionDetails.startDate);
    const transactionEndDate = moment(vm.transactionDetails.endDate);
    const elapsedTime = transactionEndDate.diff(transactionStartDate);
    return `${moment.utc(elapsedTime).format("HH:mm")} h`;
  }

  public get transactionId() {
    return this.props.match.params.transactionId;
  }

  public get evesId() {
    return this.props.match.params.evseid;
  }

  public downloadSignedMeterValues = () => {
    const cpdService = new ChargePointDetailsService(this.root);
    const { t } = this.props;
    const fileName = `${t("transactionDetails.signedMeterValuesFileName")}${this.transactionId}`;
    cpdService.downloadSignedMeterValues(this.transactionId).then((result) => {
      downloadFile(
        `${fileName}.xml`,
        isNotNullOrUndefined(result.data) ? result.data!.toString() : "",
        "application/xml"
      );
    });
  };

  public render() {
    const { t, vm } = this.props;
    if (!vm.transactionDetails || !vm.chargePointDetails) {
      return (
        <div className="align-and-justify-center loader-size" style={{ flex: 1 }}>
          <ReactLoading type={"spin"} color={"gray"} height={100} width={100} />
        </div>
      );
    }

    return (
      <div className="charge-point-details">
        <div className="row text-center margin-top-20 font-22">
          {t("transactionDetails.transactionStoppedTitle")}
        </div>
        <div className="row text-center margin-top-15 font-13 font-roboto-light padding-top-10">
          {t("transactionDetails.transactionStoppedDescription")}
        </div>
        <div className="two-columns-40 padding-10 align-and-justify-center">
          <div className="text-center font-roboto-light justify-center">
            <img src={chargePointImage} />
          </div>
          <div className="display-flex-column-align-left">
            <div className="row font-roboto font-huge margin-top-20 padding-bottom-15">
              <strong>{vm.transactionDetails.totalPrice.toFixed(2)}</strong>
              <span className="font-28"> {getCurrencySign(vm.transactionDetails.currency)}</span>
            </div>
            <div className="border-divider" />
            <div className="row font-roboto-light font-18 margin-top-20 margin-bottom-20">
              {this.transactionDuration}
            </div>
            <div className="row font-roboto-light font-18 margin-bottom-20">
              {vm.transactionDetails.totalKWh} {t("transactionDetails.kwhSpent")}
            </div>
          </div>
        </div>
        <div className="padding-top-30" />
        {vm.chargePointPublicPricing && (
          <div className="charge-point-details">
            <AdHocInvoicingAccountDetails
              evseid={this.evesId}
              chargePointDetails={vm.chargePointDetails}
              price={vm.chargePointPublicPricing!.centsPerKwh}
              isFreeChargingApplied={true}
            />
          </div>
        )}
        {vm.transactionDetails.signedMeterValues && (
          <div className="row padding-10">
            <button className="button" onClick={this.downloadSignedMeterValues}>
              {t("transactionDetails.downloadSignedMeterValues")}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default CompletedTransactionDetailsComponent;
