import * as React from "react";

import IdProvider from "utils/IdProvider";
import OptionModel from "domain/models/OptionModel";
import { isNullOrUndefined } from "util";
import styled from "styled-components";

interface IProps {
  selected?: string | boolean;
  options: OptionModel[];
  onChange: (val: any) => void;
  placeholder?: string;
  title?: string;
  disabled?: boolean;
  defaultOptionLabel?: string;
  bordered?: boolean;
}

interface IState {
  selected: string;
}

export default class InputSelect extends React.Component<IProps, IState> {
  private readonly componentId = IdProvider.stringId;

  public static defaultProps: IProps = {
    options: [],
    selected: "",
    disabled: false,
    onChange: () => {}
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      selected: ""
    };
  }

  public static getDerivedStateFromProps(newProps: IProps): Partial<IState> {
    return {
      selected: isNullOrUndefined(newProps.selected) ? "" : newProps.selected.toString()
    };
  }

  private handleChange = (value: string) => {
    this.props.onChange(value);
  };

  public render() {
    const { defaultOptionLabel, disabled, title, options, bordered } = this.props;
    const className = !bordered ? "input-group" : undefined;

    return (
      <SelectWrapper className={className}>
        <label className="label" htmlFor={this.componentId}>
          {title}
        </label>
        <select
          id={this.componentId}
          value={this.state.selected.toString()}
          className="input"
          disabled={disabled}
          required
          onChange={(evt) => this.handleChange(evt.target.value)}
        >
          {defaultOptionLabel && (
            <option key={options.length} value={""} disabled>
              {defaultOptionLabel}{" "}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </SelectWrapper>
    );
  }
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .label {
    width: 100%;
    text-align: left;
  }
  .input {
    width: 100%;
    outline: none;
    border-radius: 10px;
    border-color: #858b92;
    height: 40px;
    font-size: 18px;
    margin-top: 12px;
    background: transparent;
    font-weight: 300;
    font-size: 18px;
    color: #131923;
    padding: 0 15px;

    &:focus {
      border-bottom: 1px solid #131923;
    }
    &:focus + .label {
      color: #131923;
    }
    &:focus::placeholder {
      opacity: 1;
      color: #3f3f3f;
    }
    &:valid + .label,
    &:focus + .label,
    &:disabled + .label {
      font-size: 16px;
      top: -21px;
      pointer-events: none;
    }
    &::placeholder {
      opacity: 0;
    }
    &:invalid {
      box-shadow: none;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
