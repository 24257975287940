import Logo from "components/Logo";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class StepPlugIn extends React.Component<WithTranslation> {
  /* HTML structure and style directly taken from reev website */
  public render() {
    const { t } = this.props;

    return (
      <div className="footer-legacy">
        <footer className="footer" id="footer">
          <div className="container">
            <div className="footer__inner">
              <div className="row">
                <div className="footer__second">
                  <div className="contact contact--footer">
                    <div className="row">
                      <div className="contact__text">
                        <div className="contact__title">{t("footer.contactTitle")}</div>
                        <p className="contact__description">{t("footer.contactDescription")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer__separator row">
                <div className="footer__bottom-first">
                  <div className="footer__copyright-wrapper padding-bottom-30">
                    <div>
                      <span className="footer__logo">
                        <a href="https://reev.com">
                          <Logo fontColor="#fff" height={25} />
                        </a>
                      </span>
                    </div>
                    <div className="margin-bottom-20">© {new Date().getFullYear()}</div>
                    <a target="" href="https://reev.com/impressum/">
                      {t("footer.imprint")}
                    </a>
                    &nbsp;-&nbsp;
                    <a target="" href="https://reev.com/datenschutz/">
                      {t("footer.dataPrivacy")}
                    </a>
                    &nbsp;-&nbsp;
                    <a target="" href="https://reev.com/kontakt/">
                      {t("footer.contact")}
                    </a>
                    &nbsp;-&nbsp;
                    <a target="" href="https://reev.com/jobs/">
                      {t("footer.jobs")}
                    </a>
                  </div>
                </div>
                <div className="footer__icons">
                  <a href="mailto:info@reev.com">
                    <i className="icon-mail" />
                  </a>
                  <a href="https://www.xing.com/companies/reev/">
                    <i className="icon-xing" />
                  </a>
                  <a href="https://www.linkedin.com/company/reevmobility/">
                    <i className="icon-linkedin" />
                  </a>
                  <a href="https://www.facebook.com/reevmobility/">
                    <i className="icon-facebook" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default withTranslation()(StepPlugIn);
