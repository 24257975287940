import Routes from "enums/Routes";
import { cleanEvseID } from "./EvseId";

// This list of ABL stickers that are onboarded on beta, so we need redirect to beta if we find one of these EVSEids
const ablQrCodeList = [
  "ENS9C",
  "EU95R",
  "E5JUP",
  "EF9YP",
  "EYZ77",
  "EWH9C",
  "EAQ2R",
  "EE9XT",
  "E7CQN",
  "EYZ5L",
  "EX6NE",
  "E3UDD",
  "ET8CE",
  "EVB5B",
  "EJL8X",
  "ET1TL",
  "E536C",
  "EM3KY",
  "EP1LL",
  "E3Q9T",
  "E9PCE",
  "EMXA4",
  "ENPS3",
  "E7M2T",
  "EJ6WW",
  "E2XW4",
  "EGT3V",
  "E7T1G",
  "E6444",
  "E5CK1",
  "EVUP4",
  "EX4CU"
];

export function isAblQrCode(evseId: string) {
  // Do not redirect on beta
  if (location.hostname.includes("beta.reev")) {
    return false;
  }

  evseId = cleanEvseID(evseId);
  return ablQrCodeList.includes(evseId);
}

export function redirectAblQrCode(evseId: string) {
  evseId = cleanEvseID(evseId);
  window.location.href = `https://beta.reev.one${Routes.QR}?evseid=DEONE${evseId}`;
}
