import * as React from "react";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import styled, { css } from "styled-components";
import IdProvider from "utils/IdProvider";

interface IProps {
  id?: string;
  textBefore?: string;
  value: string;
  onChange?: (val: string) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  type?: string;
}

const InputTextV2: React.FC<IProps> = ({
  id,
  textBefore,
  label,
  type = "text",
  disabled,
  onChange,
  ...additionalProperties
}) => {
  const componentId = id || IdProvider.stringId;
  const textBeforeRef = useRef<HTMLDivElement>(null);
  const [textBeforeWidth, setTextBeforeWidth] = useState(0);

  useEffect(() => {
    if (textBeforeRef && textBeforeRef.current) {
      setTextBeforeWidth(textBeforeRef.current.clientWidth);
    }
  }, [textBeforeRef, textBefore]);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(evt.target.value);
    }
  };

  return (
    <Wrapper disabled={disabled}>
      {textBefore && <TextBefore ref={textBeforeRef}>{textBefore}</TextBefore>}
      {label && <label htmlFor={componentId}>{label}</label>}
      <StyledInput
        disabled={disabled}
        textBeforeWidth={textBeforeWidth}
        maxLength={5}
        id={componentId}
        type={type}
        onChange={handleChange}
        {...additionalProperties}
      />
    </Wrapper>
  );
};

const TextBefore = styled.div`
  position: absolute;
  top: 40px;
  left: 17px;
  color: #858b92;
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  ${({ disabled }: { disabled?: boolean }) => disabled && disabledStyle};
  label {
    text-align: left;
    margin-bottom: 10px;
  }
`;

const disabledStyle = css`
  opacity: 0.4;
  cursor: not-allowed;
`;

const StyledInput = styled.input<{ textBeforeWidth: number; disabled?: boolean }>`
  border-color: #858b92;
  border-radius: 10px;
  border-width: 1px;
  outline: none;
  padding: 10px 15px 10px
    ${({ textBeforeWidth }: { textBeforeWidth: number }) => (textBeforeWidth ? textBeforeWidth + 24 : 17)}px;
  appearance: none;
`;

export default InputTextV2;
