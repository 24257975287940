import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class ChargeAtWorkInvoicingAccountDetails extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;
    return (
      <div className="padding-horizontal-20">
        <div className="section-container">
          <div className="row">
            <div className="status-message text-center padding-top-10">
              {t("chargePointDetails.onlyRegisteredUserCanCharge")}
            </div>
          </div>
          <div className="row padding-20">
            <div className="row">
              <Link className="button secondary w-100" to={"/identify"}>
                {t("chargePointDetails.useOtherStation")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChargeAtWorkInvoicingAccountDetails);
