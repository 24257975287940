import { AjaxAdapterResponse, isAjaxAdapterError } from "domain/AjaxAdapter.interface";
import { ServiceResult, ServicePromise } from "domain/services/common/ServiceResult";
import {
  IChargePointDetails,
  ServiceGetDetailsResult,
  IChargePointDetailsService,
  ITransactionDetails,
  IFileDownload
} from "./ChargePointDetailsService.interface";
import { AjaxAdapter } from "domain/AjaxAdapter";

export class ChargePointDetailsService implements IChargePointDetailsService {
  constructor(
    // @ts-ignore
    private readonly root: IRootStore,
    private readonly ajaxAdapter: AjaxAdapter = new AjaxAdapter()
  ) {}

  public getChargePointDetails = async (
    id: string,
    isNfc: boolean
  ): ServicePromise<ServiceGetDetailsResult, IChargePointDetails> => {
    const chargePointDetailsResult = (await this.ajaxAdapter.get(
      isNfc ? `/mobile-site/evse/nfc/${id}` : `/mobile-site/evse/${id}`
    )) as AjaxAdapterResponse<IChargePointDetails>;
    if (isAjaxAdapterError(chargePointDetailsResult)) {
      // we need to discuss how to handle this better
      return Promise.reject(
        `ChargePointDetailsService.getChargePointDetails(): Error in ajax adapter ${chargePointDetailsResult.message}`
      );
    }

    switch (chargePointDetailsResult.status) {
      case 200:
        return ServiceResult.ok(ServiceGetDetailsResult.OK, chargePointDetailsResult.data);
      case 404:
        return ServiceResult.fail(ServiceGetDetailsResult.NOT_FOUND);
      default:
        return Promise.reject(
          `ChargePointDetailsService.getChargePointDetails(): Unknown status code: ${chargePointDetailsResult.status}`
        );
    }
  };

  public getTransactionDetails = async (
    id: string
  ): ServicePromise<ServiceGetDetailsResult, ITransactionDetails> => {
    const transactionDetailsResult = (await this.ajaxAdapter.get(
      `/mobile-site/evse/charge-transaction/${id}`
    )) as AjaxAdapterResponse<ITransactionDetails>;
    // TODO: this endpoint requires authentification
    if (isAjaxAdapterError(transactionDetailsResult)) {
      // we need to discuss how to handle this better
      return Promise.reject(
        `ChargePointDetailsService.getTransactionDetails(): Error in ajax adapter ${transactionDetailsResult.message}`
      );
    }

    switch (transactionDetailsResult.status) {
      case 200:
        const transactionDetails = transactionDetailsResult.data;
        return ServiceResult.ok(ServiceGetDetailsResult.OK, transactionDetails);
      case 404:
        return ServiceResult.fail(ServiceGetDetailsResult.NOT_FOUND);
      default:
        return Promise.reject(
          `ChargePointDetailsService.getTransactionDetails(): Unknown status code: ${transactionDetailsResult.status}`
        );
    }
  };

  public downloadSignedMeterValues = async (
    transactionid: string
  ): ServicePromise<ServiceGetDetailsResult, IFileDownload> => {
    const result = (await this.ajaxAdapter.get(
      `/mobile-site/evse/download-sign-meter-values/${transactionid}`
    )) as AjaxAdapterResponse<IFileDownload>;
    if (isAjaxAdapterError(result)) {
      return Promise.reject(
        `ChargePointDetailsService.downloadSignedMeterValues(): Error in ajax adapter ${result.message}`
      );
    }

    switch (result.status) {
      case 200:
        const fileResult = result.data;
        return ServiceResult.ok(ServiceGetDetailsResult.OK, fileResult);
      case 404:
        return ServiceResult.fail(ServiceGetDetailsResult.NOT_FOUND);
      default:
        return Promise.reject(
          `ChargePointDetailsService.downloadSignedMeterValues(): Unknown status code: ${result.status}`
        );
    }
  };
}
