import * as QrReader from "react-qr-reader";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps {
  handleScan: (data: string) => void;
  handleError: (data: string) => void;
}

class QrCodeScan extends React.Component<IProps & WithTranslation> {
  public render() {
    const { handleScan, handleError } = this.props;
    return (
      <div>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
}

export default withTranslation()(QrCodeScan);
