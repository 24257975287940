import * as React from "react";
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import PaymentNavigation from "pods/payment/components/PaymentNavigation";
import env from "env";
import UserModel from "../models/UserModel";
import { toEuro } from "utils/toEuro";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "pods/payment/components/CheckoutForm";

const ccInfo = require("../../../../assets/img/icons/cc-info.png");

interface IProps {
  consumerId: string;
  evseId: string;
  transactionClientSecret: string;
  accountId: string;
  onBackClick: () => void;
  minCostInCents: number;
  maxCostInCents: number;
  user: UserModel;
  currencyCode: string;
  countryCode: string;
}

const StepCardInfo: React.FC<IProps & WithTranslation> = ({
  onBackClick,
  transactionClientSecret,
  accountId,
  consumerId,
  evseId,
  user,
  minCostInCents,
  maxCostInCents,
  currencyCode,
  countryCode
}) => {
  const { t, i18n } = useTranslation();
  const stripePromise = React.useMemo(
    () =>
      loadStripe(env.stripeClientKey, {
        stripeAccount: accountId,
        locale: i18n.language as StripeElementLocale
      }),
    [accountId]
  );

  return (
    <>
      <div className="row align-and-justify-center padding-top-20">
        <img src={ccInfo} alt={t("welcome.welcome")} width={55} height={49} />
      </div>
      <PaymentNavigation
        step={2}
        stepsTotal={2}
        title={t("pay.stepPayment.title")}
        onBackClick={onBackClick}
      />
      <div className="padding-20 no-padding-bot">
        <div className="row font-roboto-medium font-18 padding-top-20 padding-bottom-10 text-center">
          {t("pay.stepContactInfo.ccInfoLabel", {
            max: toEuro(maxCostInCents),
            currency: currencyCode
          })}
        </div>
        <div className="row">
          <Elements key={i18n.language} stripe={stripePromise}>
            <CheckoutForm
              evseId={evseId}
              consumerId={consumerId}
              transactionClientSecret={transactionClientSecret}
              user={user}
              minCostInCents={minCostInCents}
              maxCostInCents={maxCostInCents}
              currencyCode={currencyCode}
              countryCode={countryCode}
            />
          </Elements>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(StepCardInfo);
