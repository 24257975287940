/* global document */
import "es6-shim";

import "utils/error-reporting/init";

import * as React from "react";
import { configure } from "mobx";
import { BrowserRouter as Router } from "react-router-dom";
import { default as Routes } from "routes";
import { render } from "react-dom";
import { Route } from "react-router";
import { RootStoreContext } from "./RootStoreContext";
import "utils/i18n";

// import german locale for moment
import "moment/locale/de";
import "../assets/less/main.less";
import { createRootStore } from "domain/createRootStore";
import ErrorBoundary from "utils/error-reporting/ErrorBoundary";

configure({ enforceActions: "observed" });
const reactRoot = document.getElementById("reactroot");

const root = createRootStore();

render(
  <RootStoreContext.Provider value={root}>
    <ErrorBoundary>
      <Router>
        <Route component={Routes} />
      </Router>
    </ErrorBoundary>
  </RootStoreContext.Provider>,
  reactRoot
);
