export class IdProvider {
  private counter = 1;

  get id() {
    return ++this.counter;
  }

  get stringId() {
    return `cid-${++this.counter}`;
  }
}

export default new IdProvider();
