import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ChargePointSectionItem from "./ChargePointSectionItem";
import ProviderAndPriceInfo from "./ProviderAndPriceInfo";
import PaymentMethodsItem from "./PaymentMethodsItem";
import { ChargePointDetailsModel, ChargePointStatus } from "domain/models/ChargePointDetailsModel";
import ChargePointSpecs from "./ChargePointSpecs";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";

interface IProps {
  chargePointDetails: ChargePointDetailsModel;
  price: number;
  evseid: string;
  isFreeChargingApplied: boolean;
}

class AdHocInvoicingAccountDetails extends React.Component<IProps & WithTranslation> {
  private get canUserProceedWithProcess(): boolean {
    const { chargePointDetails, price, isFreeChargingApplied } = this.props;
    const hasInvoicingAccountAssigned = chargePointDetails.invoicingAccounts.length > 0;
    const isChargePointInProperStateToProceed =
      chargePointDetails.status === ChargePointStatus.AVAILABLE ||
      chargePointDetails.status === ChargePointStatus.OCCUPIED;
    const isPaymentActive = chargePointDetails.cpoInformation.isPaymentActive;

    return (
      isChargePointInProperStateToProceed &&
      hasInvoicingAccountAssigned &&
      isPaymentActive &&
      isNotNullOrUndefined(price) &&
      !isFreeChargingApplied
    );
  }

  private get nextStepRoute(): string {
    const { chargePointDetails, evseid } = this.props;
    if (chargePointDetails.status === ChargePointStatus.AVAILABLE) {
      return `/helpers/car-not-plugged-in/${evseid}`;
    }
    return `/pay/${evseid}/contact-info`;
  }

  public render() {
    const { t, chargePointDetails, price } = this.props;
    return (
      <>
        <div className="padding-20">
          <div className="section-container">
            <ProviderAndPriceInfo
              cpoInformation={chargePointDetails.cpoInformation}
              price={price}
              country={chargePointDetails.country}
            />
            {this.canUserProceedWithProcess && (
              <NavLink to={this.nextStepRoute} className="button primary w-100">
                {t("chargePointDetails.chargeHereButton")}
              </NavLink>
            )}
          </div>
        </div>
        <ChargePointSectionItem
          hasToggleOption={false}
          title={t("chargePointDetails.actions.informationsAboutChargingStation")}
        >
          <div className="row padding-horizontal-20">
            <div className="section-container">
              <ChargePointSpecs hasSignedMeterValues={chargePointDetails.signedMeterValues} />
            </div>
          </div>
        </ChargePointSectionItem>
        <ChargePointSectionItem
          title={t("chargePointDetails.actions.paymentMethods")}
          hasToggleOption={false}
        >
          <div className="padding-20 no-padding-top">
            <div className="section-container">
              <PaymentMethodsItem />
            </div>
          </div>
        </ChargePointSectionItem>
      </>
    );
  }
}

export default withTranslation()(AdHocInvoicingAccountDetails);
