import { observable, computed, action } from "mobx";

import { ChargePointDetailsModel } from "domain/models/ChargePointDetailsModel";
import { IRootStore } from "domain/RootStore.interface";
import { IChargePointDetailsStore } from "./ChargePointDetailsStore.interface";
import { TransactionDetailsModel } from "domain/models/TransactionDetailsModel";

export class ChargePointDetailsStore implements IChargePointDetailsStore {
  constructor(
    // @ts-ignore
    private readonly root: IRootStore
  ) {}

  @observable private _chargePointDetails: ChargePointDetailsModel;
  @observable private _transactiontDetails: TransactionDetailsModel;

  @computed public get chargePointDetails() {
    return this._chargePointDetails;
  }
  @action public setChargePointDetails(chargePointDetails: ChargePointDetailsModel) {
    this._chargePointDetails = chargePointDetails;
    return this.chargePointDetails;
  }

  public getChargePointDetails = async (id: string, isNfc: boolean) => {
    const chargePointDetailsResult = await this.root.chargePointDetailsService.getChargePointDetails(
      id,
      isNfc
    );
    if (!chargePointDetailsResult.ok) {
      return Promise.reject(chargePointDetailsResult);
    }

    this.setChargePointDetails(
      new ChargePointDetailsModel(
        chargePointDetailsResult.data!.evseId,
        chargePointDetailsResult.data!.status,
        chargePointDetailsResult.data!.cpoInformation,
        chargePointDetailsResult.data!.invoicingAccounts,
        chargePointDetailsResult.data!.signedMeterValues,
        chargePointDetailsResult.data!.country,
        chargePointDetailsResult.data!.isReadyForCharging
      )
    );

    return Promise.resolve(this.chargePointDetails);
  };

  @computed public get transactionDetails() {
    return this._transactiontDetails;
  }
  @action public setTransactionDetails(transactionDetails: TransactionDetailsModel) {
    this._transactiontDetails = transactionDetails;
    return this.transactionDetails;
  }

  public getTransactionDetails = async (transactionId: string) => {
    const transactionDetailsResult = await this.root.chargePointDetailsService.getTransactionDetails(
      transactionId
    );
    if (!transactionDetailsResult.ok) {
      return Promise.reject(transactionDetailsResult);
    }

    this.setTransactionDetails(
      new TransactionDetailsModel(
        transactionDetailsResult.data!.id,
        transactionDetailsResult.data!.chart,
        transactionDetailsResult.data!.endDate,
        transactionDetailsResult.data!.payment,
        transactionDetailsResult.data!.currency,
        transactionDetailsResult.data!.startDate,
        transactionDetailsResult.data!.startValue,
        transactionDetailsResult.data!.totalKWh,
        transactionDetailsResult.data!.powerConsumption,
        transactionDetailsResult.data!.totalPrice,
        transactionDetailsResult.data!.signedMeterValues
      )
    );
    return Promise.resolve(this.transactionDetails);
  };
}
