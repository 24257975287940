import * as React from "react";

import { IRootStore } from "domain/RootStore.interface";
import { RootStoreContext } from "RootStoreContext";

interface IVmConnectedComponentProps {
  vm: any;
}

export const connectVm = <P extends any>(vmClass: new (root: IRootStore) => any) => {
  // tslint:disable-next-line: variable-name
  return (Component: React.ComponentType<P & IVmConnectedComponentProps>) => {
    class VmConnectHoc extends React.Component<P> {
      private readonly _vm = new vmClass(this.context);

      public static contextType = RootStoreContext;
      public context!: React.ContextType<typeof RootStoreContext>;

      public render() {
        return <Component vm={this._vm} {...this.props} />;
      }
    }

    return VmConnectHoc;
  };
};
