import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { NavLink, RouteComponentProps } from "react-router-dom";

const welcomeBg = require("../../../../assets/img/welcome-bg.jpg");
const completed = require("../../../../assets/img/completed-img.png");

interface IMatch {
  transactionId: string;
}

interface IProps extends RouteComponentProps<IMatch>, WithTranslation {}

class PaymentCompleted extends React.Component<IProps & WithTranslation> {
  public render() {
    const { t } = this.props;
    const { transactionId } = this.props.match.params;

    return (
      <>
        <div className="pay">
          <div className="image">
            <img src={welcomeBg} alt={t("welcome.welcome")} />
          </div>
          <div className="align-and-justify-center completed-image-container">
            <img src={completed} alt={t("welcome.welcome")} width={80} height={80} />
          </div>
          <div className="padding-top-20 align-and-justify-center font-roboto-medium font-28">
            {t("pay.stepCompleted.title")}
          </div>
          <div className="padding-top-20 align-and-justify-center font-roboto-light font-18">
            {t("pay.stepCompleted.subtitle")}
          </div>
          <div className="padding-top-40 align-and-justify-center font-roboto-light font-18">
            {t("pay.stepCompleted.transactionIdInfo")}
          </div>
          <div className="padding-bottom-40 align-and-justify-center font-roboto-light font-18">
            <NavLink to={`/transaction-details/${transactionId}`}>{transactionId}</NavLink>
          </div>
          <div className="padding-20 align-and-justify-center font-roboto-light font-13 text-center">
            {t("pay.stepCompleted.description")}
          </div>
          <div className="padding-top-20 align-and-justify-center">
            <NavLink to={`/transaction-details/${transactionId}`} className="button primary">
              {t("common.buttonOk")}
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PaymentCompleted);
