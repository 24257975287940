import { computed, action } from "mobx";

import { IRootStore } from "domain/RootStore.interface";
import UserModel from "../models/UserModel";
import { isEmail } from "utils/isEmail";

export class StepContactInfoVm {
  constructor(private readonly root: IRootStore) {}

  private _refreshing: boolean = false;

  public get refreshing() {
    return this._refreshing;
  }

  public registerUser = async (user: UserModel) => {
    this._refreshing = true;
    this.setErrorCode();
    try {
      await this.root.paymentStore.registerUser(user);

      this._refreshing = false;
    } catch (error) {
      this.setErrorCode(error);
    }
  };

  @action public setClientSecret(transactionClientSecret: string | null) {
    this.root.paymentStore.setTransactionClientSecret(transactionClientSecret);
  }

  @computed public get transactionClientSecret() {
    return this.root.paymentStore.transactionClientSecret;
  }

  @computed public get user() {
    return this.root.paymentStore.user;
  }

  @action public setUser(user: UserModel) {
    this.root.paymentStore.setUser(user);
  }

  public setTestData() {
    this.user.update({
      email: "thomas.mueller@reev.com",
      hasAcceptedAgb: true,
      hasAcceptedTerms: true,
      hasAcceptedOperatorTerms: true
    });
  }

  @computed public get complete() {
    return this.root.paymentStore.complete;
  }

  @action public setErrorCode(errorCode?: string) {
    this.root.paymentStore.setErrorCode(errorCode);
  }

  @computed public get errorCode() {
    return this.root.paymentStore.errorCode;
  }

  @computed public get isFormInvalid() {
    return [
      !isEmail(this.root.paymentStore.user.email),
      !this.root.paymentStore.user.email,
      !this.root.paymentStore.user.hasAcceptedTerms,
      !this.root.paymentStore.user.hasAcceptedAgb
    ].some((e) => e);
  }

  public getChargePointDetails = async (id: string) => {
    await this.root.chargePointDetailsStore.getChargePointDetails(id, false);
  };

  @computed public get chargePointDetails() {
    return this.root.chargePointDetailsStore.chargePointDetails;
  }

  @computed public get minCostInCents() {
    return this.root.paymentStore.minCostInCents;
  }

  @computed public get maxCostInCents() {
    return this.root.paymentStore.maxCostInCents;
  }

  @computed public get consumerId() {
    return this.root.paymentStore.consumerId;
  }
}
