import * as React from "react";

interface IProps {
  renderContent: boolean;
}

export default class RenderIf extends React.Component<IProps> {
  public render() {
    const { children, renderContent } = this.props;

    if (renderContent) {
      return children || null;
    } else {
      return <></>;
    }
  }
}
