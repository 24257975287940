import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";

import Footer from "components/layout/Footer";
import HelpIndexRoute from "pods/help/HelpIndexRoute";
import Navigation from "components/layout/Navigation";
import PaymentIndexRoute from "pods/payment/PaymentIndexRoute";
import WelcomeRoute from "pods/welcome/WelcomeRoute";
import IdentifyChargePointOptionsIndexRoute from "pods/identify-charge-point/IdentifyChargePointOptionsIndexRoute";
import { ChargePointDetailsContainer } from "./charge-point-details/ChargePointDetails.container";
import { TransactionDetailsContainer } from "./transaction-details/TransactionDetails.container";
import { CompletedTransactionDetailsContainer } from "./transaction-details/CompletedTransactionDetails.container";
import { CarNotPluggedInStatusContainer } from "./user-helper-steps/CarNotPluggedInStatus.container";
import Routes from "enums/Routes";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 60px;
  min-height: 100%;
`;

class IndexRoute extends React.Component<WithTranslation> {
  public render() {
    return (
      <Container>
        <Navigation />
        <Switch>
          <Route path={Routes.WELCOME} component={WelcomeRoute} />
          <Route path={Routes.HELP} component={HelpIndexRoute} />
          <Route path={Routes.IDENTIFY} component={IdentifyChargePointOptionsIndexRoute} />
          <Route path={Routes.PAY} component={PaymentIndexRoute} />
          <Route path={Routes.CHARGE} component={HelpIndexRoute} />
          <Route path={Routes.QR} component={ChargePointDetailsContainer} />
          <Route path={Routes.CHARGE_POINT_DETAILS} component={ChargePointDetailsContainer} />
          <Route path={Routes.TRANSACTION_DETAILS} component={TransactionDetailsContainer} />
          <Route
            path={Routes.COMPLETED_TRANSACTION_DETAILS}
            component={CompletedTransactionDetailsContainer}
          />
          <Route path={Routes.HELPERS_CAR_NOT_PLUGGED} component={CarNotPluggedInStatusContainer} />
          <Redirect from={Routes.NFC} to={{
            pathname: Routes.QR,
            search: location.search + "&nfc=true"
          }} />
          <Redirect from={Routes.ROOT} to={Routes.WELCOME} />
        </Switch>
        <Footer />
      </Container>
    );
  }
}

export default withTranslation()(IndexRoute);
