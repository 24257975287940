export function downloadFile(
  fileName = "download_file.json",
  content: string,
  applicationType = "application/json"
) {
  const blob = new Blob([content], {
    type: `${applicationType};charset=utf-8`
  });

  if (Boolean(window.navigator.msSaveBlob)) {
    window.navigator.msSaveBlob(blob, fileName);
  } else if (window.URL) {
    const url = window.URL.createObjectURL(blob);
    const downloadAnchorElement = document.createElement("a");

    document.body.appendChild(downloadAnchorElement);

    downloadAnchorElement.setAttribute("href", url);
    downloadAnchorElement.setAttribute("download", fileName);
    downloadAnchorElement.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(downloadAnchorElement);
  } else {
    throw Error(`File download not supported by browser: ${window.navigator.userAgent}`);
  }
}
