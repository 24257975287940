import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import HelpCarousel from "pods/help/components/HelpCarousel";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";

class StepUnplug extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <>
        <ScrollToTopOnMount />
        <HelpCarousel
          step={5}
          leftLink="/help/charge"
          icon="icon-white icon-unplug"
          title={t("help.unplug.title")}
        />
        <div className="row padding-20">
          <div className="help-bullet">{t("help.unplug.bullet1")}</div>
        </div>
        <div className="row padding-20 no-padding-top">
          <div className="help-bullet">{t("help.unplug.bullet2")}</div>
        </div>
      </>
    );
  }
}

export default withTranslation()(StepUnplug);
