import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

class PaymentMethodsItem extends React.Component<WithTranslation> {
  public render() {
    return (
      <div className="payment-methods display-flex font-roboto-light font-15">
        <div className="display-flex font-roboto-light font-15">
          <div className="icon-visa margin-right-10" />
        </div>
        <div className="display-flex font-roboto-light font-15">
          <div className="icon-mastercard margin-right-10" />
        </div>
        <div className="display-flex font-roboto-light font-15">
          <div className="icon-apple-pay margin-right-10" />
        </div>
        <div className="display-flex font-roboto-light font-15">
          <div className="icon-google-pay margin-right-10" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PaymentMethodsItem);
