import { AxiosResponse, AxiosError } from "axios";

export type AjaxAdapterResponse<T> = AxiosResponse<T> | AxiosError<IAjaxAdapterErrorResponse>;

export const isAjaxAdapterError = <T>(
  response: AjaxAdapterResponse<T>
): response is AxiosError<IAjaxAdapterErrorResponse> => {
  return (response as AxiosError<IAjaxAdapterErrorResponse>).isAxiosError !== undefined;
};

export interface IAjaxAdapterErrorResponse {
  error: {
    message: string;
    params: string[];
    fieldErrors: {
      [key: string]: string;
    };
    errorCode: string;
  };
  status: number;
}

export interface IInvokeOptions {
  noAuthentication?: boolean;
  background?: boolean;
  headers?: any;
}

export interface IAjaxAdapter {
  get: <T>(url: string, options?: IInvokeOptions) => Promise<AjaxAdapterResponse<T>>;
  post: <T>(url: string, data?: any, options?: IInvokeOptions) => Promise<AjaxAdapterResponse<T>>;
  put: <T>(url: string, data?: any, options?: IInvokeOptions) => Promise<AjaxAdapterResponse<T>>;
  delete: <T>(url: string, data?: any, options?: IInvokeOptions) => Promise<AjaxAdapterResponse<T>>;
}
