import { AjaxAdapterResponse, isAjaxAdapterError } from "domain/AjaxAdapter.interface";
import { ServiceResult, ServicePromise } from "domain/services/common/ServiceResult";
import {
  StopTransactionServiceResult,
  StopTransactionServiceData,
  IStopTransactionService
} from "./StopTransactionService.interface";
import { AjaxAdapter } from "domain/AjaxAdapter";

export class StopTransactionService implements IStopTransactionService {
  private readonly ajaxAdapter: AjaxAdapter = new AjaxAdapter();

  public stopTransaction = async (
    id: string
  ): ServicePromise<StopTransactionServiceResult, StopTransactionServiceData> => {
    const stopTransactionResult = (await this.ajaxAdapter.post(
      `/mobile-site/ad-hoc/seamless/remote-stop-transaction/${id}`
    )) as AjaxAdapterResponse<StopTransactionServiceResult>;
    if (isAjaxAdapterError(stopTransactionResult)) {
      return Promise.reject(
        `StopTransactionService.stopTransaction(): Error in ajax adapter ${stopTransactionResult.message}`
      );
    }

    switch (stopTransactionResult.status) {
      case 200:
        return ServiceResult.ok(StopTransactionServiceResult.OK);
      case 404:
        return ServiceResult.fail(StopTransactionServiceResult.NOT_FOUND);
      default:
        return Promise.reject(
          `StopTransactionService.stopTransaction(): Unknown status code: ${stopTransactionResult.status}`
        );
    }
  };
}
