import * as React from "react";

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<{}, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // @ts-ignore The parameter 'error' is not used, but it is useful to see the signature here
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // @ts-ignore The parameter 'info' is not used, but it is useful to see the signature here
  public componentDidCatch(error: any, info: any) {
    console.error(error);
  }

  public render() {
    // TODO
    // if (this.state.hasError) {
    //   return <h1>Error occured </h1>;
    // }

    return this.props.children;
  }
}

export default ErrorBoundary;
