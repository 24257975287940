import * as React from "react";
import { WithTranslation } from "react-i18next";

import { TransactionDetailsVm } from "./TransactionDetailsVm";
import { RouteComponentProps } from "react-router";
import ChargePointSectionItem from "pods/charge-point-details/components/ChargePointSectionItem";
import ReactLoading from "react-loading";
import * as moment from "moment";
import { isNotNullOrUndefined } from "utils/isNotNullOrUndefined";
import { ChargePointStatus } from "domain/models/ChargePointDetailsModel";
import ChargePointOfflineState from "./components/ChargePointOfflineState";
import Routes, { replaceLocation } from "enums/Routes";
import { getCurrencySign } from "domain/models/TransactionDetailsModel";

const chargePointImage = require("../../../assets/img/charge-point.svg");

interface IProps extends WithTranslation {
  vm: TransactionDetailsVm;
}

interface IState {
  loading: boolean;
  ongoingTransactionDuration: string;
  attempt: number;
}
interface IMatch {
  transactionId: string;
  evseid: string;
}

class TransactionDetailsComponent extends React.Component<
  IProps & RouteComponentProps<IMatch>,
  IState
> {
  public interval: any;
  public intervalAfterStopedTransaction: any;
  constructor(props: IProps & RouteComponentProps<IMatch>) {
    super(props);
    this.state = {
      attempt: 0,
      loading: false,
      ongoingTransactionDuration: "0 h"
    };
  }
  public componentDidMount() {
    const { vm, history } = this.props;
    const { transactionId, evseid } = this.props.match.params;
    const that = this;

    setTimeout(() => {
      vm.getTransactionDetails(transactionId).catch(() => that.setState({ attempt: 1 }));
      vm.getChargePointDetails(evseid).catch(() => history.push(Routes.WELCOME));
    }, 1500);
    this.interval = setInterval(() => {
      if (that.state.attempt > 6) {
        history.push(Routes.PAY_FAILED);
      }
      vm.getTransactionDetails(transactionId).catch(() =>
        that.setState((prevState) => ({ attempt: prevState.attempt + 1 }))
      );
    }, 10000);
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalAfterStopedTransaction);
  }

  public stopTransaction = () => {
    const { t, vm } = this.props;
    const { transactionId } = this.props.match.params;
    if (window.confirm(t("transactionDetails.stopTransactionQuestion"))) {
      this.setState({ loading: true, attempt: 0 });
      vm.stopTransaction(transactionId).then(() => {
        this.checkIfTransactionIsStoppedAndReload(transactionId);
      });
    }
  };

  private checkIfTransactionIsCompletedAndRedirect = () => {
    const { history, vm } = this.props;
    const { transactionId, evseid } = this.props.match.params;
    if (vm.transactionDetails.id === transactionId && vm.transactionDetails.endDate) {
      history.push(
        replaceLocation(Routes.COMPLETED_TRANSACTION_DETAILS, {
          evseid,
          transactionId
        })
      );
    }
  };

  public checkIfTransactionIsStoppedAndReload(transactionId: string) {
    const { vm, history } = this.props;
    const { evseid } = this.props.match.params;
    const that = this;
    this.intervalAfterStopedTransaction = setInterval(() => {
      if (that.state.attempt > 6) {
        clearInterval(that.intervalAfterStopedTransaction);
        that.setState({ loading: false });
      } else {
        vm.getTransactionDetails(transactionId)
          .then(() => {
            if (vm.transactionDetails.id === transactionId && vm.transactionDetails.endDate) {
              history.push(
                replaceLocation(Routes.COMPLETED_TRANSACTION_DETAILS, { evseid, transactionId })
              );
            }
          })
          .catch(() => that.setState(({ attempt }) => ({ attempt: attempt + 1 })));
      }
    }, 10000);
  }

  public get ongoingTransactionDuration() {
    const { vm } = this.props;
    const transactionStartDate = moment(vm.transactionDetails.startDate);
    const transactionEndDate = moment(new Date());
    const elapsedTime = transactionEndDate.diff(transactionStartDate);
    return `${moment.utc(elapsedTime).format("HH:mm")} h`;
  }

  public render() {
    const { t, vm } = this.props;
    const { loading } = this.state;
    if (!vm.transactionDetails || !vm.chargePointDetails || loading) {
      return (
        <div
          className="align-and-justify-center loader-size"
          style={{
            flex: 1
          }}
        >
          <ReactLoading type={"spin"} color={"gray"} height={100} width={100} />
        </div>
      );
    }
    this.checkIfTransactionIsCompletedAndRedirect();

    if (vm.chargePointDetails.status === ChargePointStatus.OFFLINE) {
      return <ChargePointOfflineState evseId={vm.chargePointDetails.evseId} />;
    }
    return (
      <div className="charge-point-details">
        <div className="row text-center margin-top-20 font-22">{t("transactionDetails.title")}</div>
        <div className="two-columns-40 padding-10 align-and-justify-center">
          <div className="text-center font-roboto-light justify-center">
            <img src={chargePointImage} />
          </div>
          <div className="display-flex-column-align-left">
            <div className="row font-roboto font-huge margin-top-20 padding-bottom-15">
              <strong>{vm.transactionDetails.totalPrice.toFixed(2)}</strong>
              <span className="font-28"> {getCurrencySign(vm.transactionDetails.currency)}</span>
            </div>
            <div className="border-divider" />
            <div className="row font-roboto-light font-18 margin-top-20 margin-bottom-20">
              {this.ongoingTransactionDuration}
            </div>
            <div className="row font-roboto-light font-18 margin-bottom-20">
              {vm.transactionDetails.totalKWh} {t("transactionDetails.kwhSpent")}
            </div>
          </div>
        </div>
        <ChargePointSectionItem title={t("transactionDetails.currentLoad")} hasToggleOption={false}>
          <div className="padding-horizontal-20">
            <div className="section-container">
              <div className="display-flex padding-10">
                <div className="flex-2 text-center font-roboto-light justify-center">
                  <div className="two-columns-40-black icon-speed icon-size-large " />
                </div>
                <div className="display-flex-vertical flex-3 margin-top-20 font-huge">
                  <div className="display-flex-justify-center flex-3">
                    <strong>
                      {isNotNullOrUndefined(vm.transactionDetails.powerConsumption)
                        ? vm.transactionDetails.powerConsumption.toFixed(2)
                        : 0}
                      <span className="font-28"> kW</span>
                    </strong>
                  </div>
                  <div className="display-flex-justify-center flex-2 font-13 font-roboto-light">
                    {isNotNullOrUndefined(vm.transactionDetails.powerConsumption) &&
                    vm.transactionDetails.powerConsumption === 0
                      ? t("transactionDetails.waitingForCurrentLoad")
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ChargePointSectionItem>
        {!vm.transactionDetails.endDate && (
          <div className="row padding-10">
            <button className="button" onClick={this.stopTransaction}>
              {t("transactionDetails.stopTransaction")}
            </button>
          </div>
        )}
        <div className="padding-top-30" />
      </div>
    );
  }
}

export default TransactionDetailsComponent;
