enum Routes {
  WHITE_LABEL_CUSTOMERID_LOGS_TRANSACTION_ID = "/white-label-admin/:customerId/logs/transaction/:id",
  WHITE_LABEL_USERS_INVITATIONS_CREATE_ACCESSIBLE = "/white-label-admin/users/invitations/create/accessible-customers",
  ROOT = "/",
  WELCOME = "/welcome",
  HELP = "/help",
  IDENTIFY = "/identify",
  PAY = "/pay/:evseid",
  CHARGE = "/charge",
  QR = "/qr",
  CHARGE_POINT_DETAILS = "/charge-point-details/:evseid",
  TRANSACTION_DETAILS = "/transaction-details/:transactionId/:evseid",
  COMPLETED_TRANSACTION_DETAILS = "/completed-transaction-details/:transactionId/:evseid",
  HELPERS_CAR_NOT_PLUGGED = "/helpers/car-not-plugged-in/:evseid",
  PAY_EMAIL_INFO = "/pay/:evseid/email-info",
  PAY_CONTACT_INFO = "/pay/:evseid/contact-info",
  PAY_CARD_INFO = "/pay/:evseid/card-info",
  PAY_COMPLETED = "/pay/completed/:transactionId",
  PAY_FAILED = "/pay/failed",
  NFC = "/nfc"
}

interface IUrlParam {
  evseid?: string;
  transactionId?: string;
}

export const replaceLocation = (route: Routes, options: IUrlParam): Routes => {
  Object.keys(options).forEach((element: keyof IUrlParam) => {
    // @ts-ignore
    route = route.replace(`:${element}`, options[element]) as Routes;
  });
  return route as Routes;
};

export default Routes;
