import * as React from "react";
import { Route } from "react-router-dom";

import IndexRoute from "pods/IndexRoute";

export default class Routes extends React.Component {
  public render() {
    return (
      <>
        <Route path="/" component={IndexRoute} />
      </>
    );
  }
}
