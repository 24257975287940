import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { ChargePointDetailsVm } from "./ChargePointDetailsVm";
import ChargePointDetailsComponent from "./ChargePointDetailsComponent";
import { connectVm } from "../../domain/connectVm";

// tslint:disable-next-line: variable-name
export const ChargePointDetailsContainer = connectVm(ChargePointDetailsVm)(
  withTranslation()(observer(ChargePointDetailsComponent))
);
