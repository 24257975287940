enum Languages {
  EN = "en",
  DE = "de",
  IT = "it",
  FR = "fr",
  NL = "nl"
}

export const getNativeName = (lang: Languages) => {
  switch (lang) {
    case Languages.EN:
      return "English";
    case Languages.DE:
      return "Deutsch";
    case Languages.IT:
      return "Italiano";
    case Languages.NL:
      return "Nederlands";
    case Languages.FR:
      return "Français";
  }
};

export default Languages;
