import { ServicePromise } from "../common/ServiceResult";

export enum StopTransactionServiceResult {
  OK = "OK",
  NOT_FOUND = "NOT_FOUND"
}

export enum StopTransactionServiceData {
  status = "ACCEPTED"
}

export interface IStopTransactionService {
  stopTransaction(
    id: string
  ): ServicePromise<StopTransactionServiceResult, StopTransactionServiceData>;
}
