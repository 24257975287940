import * as React from "react";
import { Route, Switch } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";

import { StepContactInfoContainer } from "./steps/StepContactInfo.container";
import StepCardInfo from "./steps/StepCardInfo";
import PaymentCompleted from "./components/PaymentCompleted";
import PaymentFailed from "./components/PaymentFailed";
import Routes from "enums/Routes";

class PaymentIndexRoute extends React.Component<WithTranslation> {
  public render() {
    return (
      <div className="pay">
        <Switch>
          <Route path={Routes.PAY_CONTACT_INFO} component={StepContactInfoContainer} />
          <Route path={Routes.PAY_CARD_INFO} component={StepCardInfo} />
          <Route path={Routes.PAY_COMPLETED} component={PaymentCompleted} />
          <Route path={Routes.PAY_FAILED} component={PaymentFailed} />
        </Switch>
      </div>
    );
  }
}

export default withTranslation()(PaymentIndexRoute);
