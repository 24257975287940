import { action, observable } from "mobx";

import IUpdatable from "utils/IUpdatable";
import { updater } from "utils/ObjectUpdater";

export default class UserModel implements IUpdatable<UserModel> {
  @observable public email: string;
  @observable public hasAcceptedTerms: boolean;
  @observable public hasAcceptedAgb: boolean;
  @observable public hasAcceptedOperatorTerms: boolean;
  @observable public locale: string = "de";
  public evseId: string;

  @action public update(obj: Partial<UserModel>): UserModel {
    return updater.update(this, obj);
  }
}
