import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { TransactionDetailsVm } from "./TransactionDetailsVm";
import TransactionDetailsComponent from "./TransactionDetailsComponent";
import { connectVm } from "../../domain/connectVm";

// tslint:disable-next-line: variable-name
export const TransactionDetailsContainer = connectVm(TransactionDetailsVm)(
  withTranslation()(observer(TransactionDetailsComponent))
);
