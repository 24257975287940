import * as React from "react";

interface IProps {
  step: number;
  stepsTotal: number;
  title: string;
  onBackClick?: () => void;
}

export default class PaymentNavigation extends React.Component<IProps> {
  public render() {
    const { step, stepsTotal, title, onBackClick } = this.props;
    const percentage = (step / stepsTotal) * 100;

    return (
      <div className="payment-navigation">
        <div className="content">
          <div className="number">
            {step}/{stepsTotal}
          </div>
          <div className="title">{title}</div>
        </div>
        <div className="progress">
          <div className="progress-indicator" style={{ width: `${percentage}%` }} />
        </div>
        <div className="navigation">
          <div className="left" onClick={onBackClick}>
            <div className="icon icon-blue icon-chevron-right horizontally-mirror-image" />
          </div>
        </div>
      </div>
    );
  }
}
