import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

const chargePointImage = require("../../../../assets/img/charge-point.svg");

interface IProps {
  evseId: string;
}

class ChargePointOfflineState extends React.Component<IProps & WithTranslation> {
  public render() {
    const { t, evseId } = this.props;
    return (
      <div className="charge-point-details">
        <div className="header-container">
          <img width="40%" src={chargePointImage} />
          <div>
            <div className="status">
              <div className="offline align-and-justify-center">{t(`common.status.offline`)}</div>
            </div>
            <div className="font-roboto-light padding-top-10">{evseId}</div>
          </div>
        </div>
        <div className="row">
          <div className="text-center font-roboto-light padding-20">
            {t("transactionDetails.chargePointWentOffline")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChargePointOfflineState);
