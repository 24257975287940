import * as React from "react";

import { RouteComponentProps } from "react-router";
import { WithTranslation } from "react-i18next";
import { ChargePointDetailsVm } from "pods/charge-point-details/ChargePointDetailsVm";
import { ChargePointStatus } from "domain/models/ChargePointDetailsModel";
import Routes, { replaceLocation } from "enums/Routes";

const chargePointImage = require("../../../assets/img/charge-point.svg");
const maximumApiCallsAttempt = 10;
interface ITParams {
  evseid: string;
}

interface IProps extends WithTranslation {
  vm: ChargePointDetailsVm;
}

class CarNotPluggedInStatusComponent extends React.Component<
  IProps & RouteComponentProps<ITParams>
> {
  public interval: number;

  private get evseId() {
    return this.props.match.params.evseid;
  }

  public componentDidMount() {
    const { vm } = this.props;
    vm.getChargePointDetails(this.evseId, false);

    let apiCallsCounter = 0;
    this.interval = window.setInterval(() => {
      if (vm.chargePointDetails.status === ChargePointStatus.OCCUPIED) {
        clearInterval(this.interval);
        return;
      }
      if (apiCallsCounter < maximumApiCallsAttempt) {
        vm.getChargePointDetails(this.evseId, false);
        apiCallsCounter++;
      } else {
        window.location.href = "/";
      }
    }, 5000);
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
  }

  public render() {
    const { t, vm, history } = this.props;
    if (!vm.chargePointDetails) {
      return <div />;
    }
    if (vm.chargePointDetails.isReadyForCharging) {
      history.push(replaceLocation(Routes.PAY_CONTACT_INFO, { evseid: this.evseId }));
    }
    return (
      <div className="charge-point-details padding-20">
        <div className="row align-and-justify-center margin-top-20 margin-bottom-20">
          <div>
            <img width="100%" src={chargePointImage} alt={t("helperSteps.carNotPluggedIn.title")} />
          </div>
        </div>
        <div className="row text-center margin-top-20 font-22">
          {t("helperSteps.carNotPluggedIn.title")}
        </div>
        <div className="row text-center font-16 padding-20 font-roboto-light">
          {t("helperSteps.carNotPluggedIn.content")}
        </div>
        <div className="margin-top-20">
          {!vm.chargePointDetails.isReadyForCharging && (
            <button className="button w-100" disabled>
              {t("common.buttonContinue")}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default CarNotPluggedInStatusComponent;
