import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ChargePointSectionItem from "./ChargePointSectionItem";
import ChargePointSpecs from "./ChargePointSpecs";

class RoamingInvoicingAccountDetails extends React.Component<WithTranslation> {
  public render() {
    const { t } = this.props;
    return (
      <div className="padding-horizontal-20">
        <div className="section-container">
          <div className="row">
            <div className="status-message text-center padding-top-10">
              {t("chargePointDetails.roamingIncluded")}
            </div>
          </div>
          <div className="row padding-20">
            <div className="row padding-top-30">
              <a className="button secondary w-100" href="https://www.hubject.com/en/intercharge/">
                {t("chargePointDetails.goToIntercharge")}
              </a>
            </div>
            <div className="padding-top-30" />
          </div>
        </div>
        <div className="padding-top-20" />
        <ChargePointSectionItem
          hasToggleOption={false}
          title={t("chargePointDetails.actions.informationsAboutChargingStation")}
        >
          <div className="row padding-horizontal-20">
            <div className="section-container">
              <ChargePointSpecs />
            </div>
          </div>
        </ChargePointSectionItem>
      </div>
    );
  }
}

export default withTranslation()(RoamingInvoicingAccountDetails);
