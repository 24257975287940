import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps {
  hasSignedMeterValues?: boolean;
}

interface IState {
  specifications: string[];
}

class ChargePointSpecs extends React.Component<IProps & WithTranslation, IState> {
  constructor(props: IProps & WithTranslation) {
    super(props);
    this.state = {
      specifications: [
        // "chargePointDetails.priceRateInformation.maxLoad",
        // "chargePointDetails.priceRateInformation.connectorType",
        "chargePointDetails.priceRateInformation.workingTime"
      ]
    };
  }

  private extendSpecifications = (specifications: string[]) => {
    const { hasSignedMeterValues } = this.props;
    if (hasSignedMeterValues) {
      specifications.push("chargePointDetails.priceRateInformation.signedMetterValuesConfirmed");
    }
    return specifications;
  };

  public componentDidMount() {
    const { specifications } = this.state;
    const extendSpecifications = this.extendSpecifications(specifications);

    this.setState({ specifications: extendSpecifications });
  }

  public render() {
    const { t } = this.props;
    const { specifications } = this.state;
    return specifications.map((specification) => {
      return (
        <div key={specification} className="charge-point-spec-item">
          <div className="font-roboto-light">{t(specification)}</div>
        </div>
      );
    });
  }
}

export default withTranslation()(ChargePointSpecs);
