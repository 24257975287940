declare const __API_URL__: any;

declare const window: Window & {
  appConfig: {
    STAGE: string;
    AD_HOC_API_URL: string;
    STRIPE_CLIENT_KEY: string;
    SENTRY_DSN_ADHOC: string;
  };
};

const isDev = window.appConfig.STAGE === "dev";

const conf = {
  apiUrl: typeof __API_URL__ !== "undefined" ? __API_URL__ : window.appConfig.AD_HOC_API_URL,
  stripeClientKey: window.appConfig.STRIPE_CLIENT_KEY,
  stage: window.appConfig.STAGE,
  isDev,
  sentryDsn: window.appConfig.SENTRY_DSN_ADHOC
};

if (isDev) {
  console.log("App Config:", conf);
}

export default conf;
