export interface IServiceResult<TStatus extends string, TData> {
  ok: boolean;
  status: TStatus;
  data?: TData;
}

export class ServiceResult<TStatus extends string, TData> implements IServiceResult<TStatus, TData> {

  public ok: boolean;
  public status: TStatus;
  public data?: TData;

  public static fail<TStatus extends string, TData>(status: TStatus, data?: TData): ServiceResult<TStatus, TData> {
    const result = new ServiceResult<TStatus, TData>();
    result.ok = false;
    result.status = status;
    result.data = data;

    return result;
  }

  public static ok<TStatus extends string, TData>(status: TStatus, data?: TData): ServiceResult<TStatus, TData> {
    const result = new ServiceResult<TStatus, TData>();
    result.ok = true;
    result.status = status;
    result.data = data;
    return result;
  }
}

export type ServicePromise<TStatus extends string, TData> = Promise<IServiceResult<TStatus, TData>>;
// This is a hack to avoid the typescript error regarding ServicePromise not matching a Promise constructor
// tslint:disable-next-line: variable-name
export const ServicePromise = Promise;
